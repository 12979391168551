import * as t from 'io-ts'
import { businessDetailsSchema } from './business'
import { optional } from './validate'
import { stepSchema, contactSchema } from './wizardStep'

export const businessDetailsInfoSchema = t.type({
  contact: optional(contactSchema),
  businessDetails: optional(businessDetailsSchema),
})

export type BusinessDetailsInfo = t.TypeOf<typeof businessDetailsInfoSchema>

export const confirmBusinessDetailsSchema = stepSchema({
  businessDetails: t.type({
    label: t.string,
    fields: businessDetailsInfoSchema,
  }),
  lockedNameFields: optional(t.boolean),
  text: t.type({
    primary: t.string,
    additional: t.string,
    lockedNameMessage: optional(t.string),
  }),
})
