import * as t from 'io-ts'
import { optional } from './validate'

const businessDetails = t.type({
  abn: t.string,
  abnStatus: t.string,
  tradingName: optional(t.string),
  tradingNames: t.array(t.string),
  entityName: t.string,
  entityTypeCode: t.string,
  entityTypeDescription: t.string,
  isTrustEntity: t.boolean,
})

export const abrLookupSchema = t.type({
  success: t.boolean,
  entityLookup: optional(businessDetails),
})

export type BusinessDetails = t.TypeOf<typeof businessDetails>
export type BusinessLookupResponse = t.TypeOf<typeof abrLookupSchema>
