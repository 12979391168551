import React from 'react'
import { css } from 'emotion'
import { useSelector } from 'react-redux'
import { getCallCentreNumber } from 'themes/selectors'

export const style = css`
  color: white;
  height: 48px;
  min-width: 100px;
  border-radius: 6px;
  border: none;
  background-color: #147cca;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 1px 0 0 #ffffff;
  font-family: Lato, Arial, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 900;
`

export default function CallUsButtonLink() {
  const callCentreNumber = useSelector(getCallCentreNumber)
  return (
    <a href={`tel:${callCentreNumber}`} className={style}>
        Give us a call on {callCentreNumber}
    </a>
  )
}
