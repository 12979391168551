import {
  InlineSvg,
  PrimaryButton,
  Spacer,
  Stack,
  Text,
  ThemeDefinition,
  Title,
  useTheme,
} from '@cx/connect-ui'
import { breakpoints, brightBlue } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import { match } from 'lib/option'
import React from 'react'
import { useSelector } from 'react-redux'
import { coBrandingPartner, getCallCentreNumber } from 'themes/selectors'
import PhoneIcon from '../../../components/icons/svg/icon-phone.svg'

const modalStyle = (theme: ThemeDefinition) => css`
  text-align: center;
  color: ${theme.baseFontColor};

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 320px;
    max-width: 320px;
  }
`

const linkStyle = css`
  color: ${brightBlue};
  text-decoration: none;
`

type Props = {
  onOkay: () => void
}

export const GiveUpAndCallAlintaModal = ({ onOkay }: Props) => {
  const theme = useTheme()
  const callCentreNumber = useSelector(getCallCentreNumber)
  const partner = useSelector(coBrandingPartner)

  return (
    <div className={cx(modalStyle(theme))}>
      <Stack spacing="generous">
        <InlineSvg
          fill={theme.baseFontColor}
          size={38}
          alt="Warning symbol"
          src={PhoneIcon}
        ></InlineSvg>
        <Title>
          Please call us on{' '}
          <a className={cx(linkStyle)} href={`tel:${callCentreNumber}`}>
            {callCentreNumber}
          </a>
        </Title>
        {match(
          partner,
          () => (
            <Text>
              Looks like we'll need to speak to you to find the right business energy plan for your address. Please call Alinta Energy on {callCentreNumber}.
            </Text>
          ),
          () => (
            <Text>We'll do our best to help you.</Text>
          )
        )}
        <Spacer height={8} />
        <PrimaryButton onClick={onOkay}>Okay</PrimaryButton>
      </Stack>
    </div>
  )
}
