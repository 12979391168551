import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { PageNavigationDirection } from '../components/util/CustomRoute'
import { pageBackground } from './backgroundGradients'

export type CampaignType = 'Community' | 'Club' | 'SME' | 'Corporate' | 'MLM' | 'Internal'
export type AgentPhoneEicDetails = {
  creatorName: string
  detailsUrl: string
}

const initialState = {
  pageIsNotFound: false,
  pageDataIsPrepared: false,
  formIsSubmitting: false,
  campaignType: undefined as CampaignType | undefined,
  agentPhoneEic: undefined as AgentPhoneEicDetails | undefined,

  data: {} as {
    [id: string]: any
    fieldAttributes?: { [id: string]: { isEditable: boolean; isHidden: boolean } }
  },
  pageBg: pageBackground.transparentGradient,
  wizardNavigationDirection: 'none' as PageNavigationDirection,
}

export type AppState = typeof initialState

export default createReducer<AppState>(initialState, (b) => {
  b.addCase(actions.pageIsNotFound, (s, { payload: { notFound } }) => {
    s.pageIsNotFound = notFound
  })

  b.addCase(actions.enteredPage, (s, _) => {
    s.pageDataIsPrepared = false
  })

  b.addCase(actions.pageDataLoaded, (s, { payload: { key, data } }) => {
    s.data[key] = data
  })

  b.addCase(actions.pageDataCleared, (s, _) => {
    s.data = {}
  })

  b.addCase(actions.pageDataIsPrepared, (s, { payload: prepared }) => {
    s.pageDataIsPrepared = prepared
  })

  b.addCase(actions.pageBackgroundColorChanged, (s, { payload: color }) => {
    s.pageBg = color
  })

  b.addCase(actions.wizardStepNavigated, (s, { payload: direction }) => {
    s.wizardNavigationDirection = direction
  })

  b.addCase(actions.formSubmissionRequestInitiated, (s, _) => {
    s.formIsSubmitting = true
  })

  b.addCase(actions.formSubmissionRequestCompleted, (s, _) => {
    s.formIsSubmitting = false
  })

  b.addCase(actions.campaignTypeLoaded, (s, { payload: campaignType }) => {
    s.campaignType = campaignType
  })

  b.addCase(actions.phoneAgentDetailsLoaded, (s, { payload: agentDetails }) => {
    s.agentPhoneEic = agentDetails
  })
})
