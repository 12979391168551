import {
  AddressAutocompleteFieldWithExperian,
  Link,
  Modal,
  Panel,
  RadioButtonTabField,
  Spacer,
  SpringResizingContainer,
  Stack,
  Text,
  TextField,
  Title,
} from '@cx/connect-ui'
import {
  AddressValue,
  AuState,
  SearchOptions,
  toReadableAddress,
} from '@cx/connect-ui/lib/components/fields/address'
import classNames from 'classnames'
import HashIcon from 'components/icons/HashIcon'
import { validateNmi } from 'cx-validators'
import { css, cx } from 'emotion'
import { formSubmissionFailure } from 'errors/selectors'
import { Form, Formik } from 'formik'
import * as Option from 'fp-ts/lib/Option'
import { CommonFieldProps, useFormikField } from 'lib/formikIntegration'
import { defaultValue, whenSome } from 'lib/option'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertyAddress } from 'remoteAssets/selectors'
import { compose, required, wrapCxValidator } from 'validation/validators'
import { NmiEntry } from 'wizard/api/contracts/nmiEntry'
import { Address } from 'wizard/components/fields/formik/address'
import NextAndBackButtonGroup from 'wizard/components/NextAndBackButtonGroup'
import NextButton from 'wizard/components/NextButton'
import { useServerValidation } from 'wizard/hooks/useServerValidation'
import Content from '../../../components/Content'
import Foreground from '../../../components/Foreground'
import Page from '../../../components/Page'
import { confirmNmiButtonClicked, FormikSubmissionPayload } from '../../actions'
import { getCheckUpdatedAddressAction, getNextAction } from '../../selectors'
import { nmiEntryInitialState } from '../../state/selectors'
import { GiveUpAndCallAlintaModal } from './GiveUpAndCallAlintaModal'
import { NmiInstructionsModal } from './NmiInstructionsModal'
import NmiLookupIcon from './nmi-lookup.png'
import CallUsButtonLink from 'wizard/components/CallUsButtonLink'
import NmiNotFoundText from './NmiNotFoundText'
import config from 'config'

type FormProps = {
  values: NmiEntry
  onSubmit: (e: any) => void
}

const IsThisAddressCorrectField = ({ name }: CommonFieldProps) => {
  const [fieldProps] = useFormikField<string>(name)

  return (
    <RadioButtonTabField
      {...fieldProps}
      label="Is this address shown above correct?"
      showDescender
      items={[
        { label: 'Address is correct', value: 'correct' },
        { label: 'I need to fix it ', value: 'incorrect' },
      ]}
    />
  )
}

const NmiEntryField = ({ name }: CommonFieldProps) => {
  const validate = compose(required('NMI'), wrapCxValidator(validateNmi))
  const [fieldProps] = useFormikField<string>(name, validate, true)

  return <TextField label="Please enter your NMI" {...fieldProps} icon={<HashIcon />} />
}

type FieldProps = CommonFieldProps & {
  enableManualEditing: boolean
  searchOptions?: SearchOptions
}
const ConnectionAddressField = ({ name, enableManualEditing, searchOptions }: FieldProps) => {
  const [fieldProps] = useFormikField<AddressValue>(
    name,
    required('Address is required'),
    true
  )

  return (
    <AddressAutocompleteFieldWithExperian
      {...fieldProps}
      label="Ok, please update your address"
      experianEndpoint={config.addressApiEndpoint}
      apimSubscriptionKey={config.apimSubscriptionKey}
      searchOptions={searchOptions}
      australiaOnly
      enableManualEditing={enableManualEditing}
      autoFocus
    />
  )
}

const textAlignmentStyle = css`
  text-align: left;
`

const nmiLookupIconStyle = css`
  margin-top: -60px;
  margin-bottom: 10px;
`

const NmiForm = ({ values, onSubmit }: FormProps) => {
  const confirmNmiAction = useSelector(getNextAction)
  const updateAddressAction = useSelector(getCheckUpdatedAddressAction)
  const property = useSelector(getPropertyAddress)
  const addressString = Option.map((p: Address) => toReadableAddress(p))(property)
  const [modalState, setModalState] = useState<'closed' | 'nmi' | 'call'>('closed')
  const error = useSelector(formSubmissionFailure)
  const searchOptions = values.addressFilter ?
    { auStates: [values.addressFilter.state as AuState],
      postcode: values.addressFilter.postcode } : undefined

  useServerValidation<NmiEntry>()

  return (
    <Form className={cx(textAlignmentStyle)}>
      <SpringResizingContainer>
        <Stack>
          <Spacer height={32} />

          <Panel color="secondary" hasDescender>
            <img alt=""
              src={NmiLookupIcon}
              height={80}
              width={70}
              className={nmiLookupIconStyle}
            />
            <Stack spacing="generous" align="center">
              <Title>You're our highest priority</Title>
              <Text>
                Looks like we need to check your business address for{' '}
                <strong>{defaultValue(addressString, 'your address')}.</strong>
              </Text>
              <Text>
                First, we'll need your NMI (National Meter Identifier) for your address.
                Your NMI is a special number required to connect your electricity, so
                let's find it now.
              </Text>
            </Stack>
          </Panel>
          <Spacer height={16} />

          <IsThisAddressCorrectField name="addressIsCorrect" />
          {values.addressIsCorrect === 'correct' && (
            <Stack spacing="generous">
              <Text bold>OK, please find your NMI and enter it below.</Text>
              <Text>
                Your NMI is a unique code of 10 or 11 characters which is usually shown at
                the top of a page in your electricity bill, alongside other details like
                your supply address and billing period.
              </Text>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setModalState('nmi')
                }}
              >
                Get more help on finding your NMI
              </Link>
              <NmiEntryField name="nmi" />

              <NextAndBackButtonGroup>
                {confirmNmiAction && (
                  <NextButton onClick={onSubmit} color="secondaryBlue">
                    {confirmNmiAction.title}
                  </NextButton>
                )}
              </NextAndBackButtonGroup>

              {whenSome(error, (e) => (
                <>
                  <NmiNotFoundText />
                  <CallUsButtonLink />
                </>
              ))}
            </Stack>
          )}
          {values.addressIsCorrect === 'incorrect' && (
            <Stack spacing="generous">
              <ConnectionAddressField name="address" enableManualEditing searchOptions={searchOptions} />
              <NextAndBackButtonGroup>
                {updateAddressAction && (
                  <NextButton onClick={onSubmit} color="secondaryBlue">
                    {updateAddressAction.title}
                  </NextButton>
                )}
              </NextAndBackButtonGroup>

              {whenSome(error, (e) => (
                <>
                  <NmiNotFoundText />
                  <CallUsButtonLink />
                </>
              ))}
            </Stack>
          )}
        </Stack>
      </SpringResizingContainer>
      <Modal isOpen={modalState !== 'closed'} onClose={() => setModalState('closed')}>
        {modalState === 'nmi' && (
          <NmiInstructionsModal
            onMoreHelp={() => setModalState('call')}
            onOkay={() => setModalState('closed')}
          />
        )}
        {modalState === 'call' && (
          <GiveUpAndCallAlintaModal onOkay={() => setModalState('closed')} />
        )}
      </Modal>
    </Form>
  )
}

type Props = {
  className?: string
}

const NmiEntryPage = ({ className }: Props) => {
  const initialState = useSelector(nmiEntryInitialState)

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    (p: FormikSubmissionPayload<NmiEntry>) => dispatch(confirmNmiButtonClicked(p)),
    [dispatch]
  )

  return (
    <Page className={classNames(className, 'aumo_confirm-customer-details-page')}>
      <Content>
        <Foreground showFormSubmissionFailureError={false}>
          <div className="aumo_confirm-customer-details-form">
            <Formik
              initialValues={initialState}
              enableReinitialize
              onSubmit={(values, actions) => {
                onSubmit({ values, actions })
              }}
            >
              {({ values, handleSubmit }) => (
                <NmiForm values={values} onSubmit={handleSubmit} />
              )}
            </Formik>
          </div>
        </Foreground>
      </Content>
    </Page>
  )
}

export default NmiEntryPage
