import { all, put, fork, takeEvery, takeLatest, call, select } from 'redux-saga/effects'
import {
  remoteAssetRegistered,
  remoteAssetRequested,
  remoteAssetLoaded,
  remoteAssetFailed,
  remoteAssetAlreadyCached,
} from './actions'
import { RootState } from '../state'
import config from '../config'
import { isAssetLoading, isAssetLoaded } from './selectors'
import { customFetch } from 'lib/api'

type RemoteAssetRequest = {
  _assets: { [id: string]: { href: string } }
}

export function* registerRemoteAssets(req: RemoteAssetRequest) {
  for (let key in req._assets) {
    yield put(remoteAssetRegistered({ key, href: req._assets[key].href }))
  }
}

function* watchRegisteredAssetAndFetch() {
  yield takeLatest('REMOTE_ASSET_REGISTERED', function* (
    action: ReturnType<typeof remoteAssetRegistered>
  ) {
    const assetKey = action.payload.key

    // For now, we eagerly load all assets when discovered (if they are not already loaded)
    const isLoaded: boolean = yield select(isAssetLoaded, assetKey)
    const isLoading: boolean = yield select(isAssetLoading, assetKey)
    const shouldFetch = !(isLoaded || isLoading)

    if (shouldFetch) {
      yield put(remoteAssetRequested(assetKey))
    } else {
      yield put(remoteAssetAlreadyCached(assetKey))
    }
  })
}

function* watchRemoteAssetRequested() {
  yield takeEvery('REMOTE_ASSET_REQUESTED', function* (
    action: ReturnType<typeof remoteAssetRequested>
  ) {
    const assetKey = action.payload
    const href = yield select((x: RootState) => x.remoteAssets.remoteAssetHrefs[assetKey])

    try {
      const res: Response = yield call(customFetch, config.endpoint + href, {})
      if (res.ok) {
        const json = yield res.json()

        yield put(remoteAssetLoaded({ key: assetKey, data: json }))
      } else {
        throw new Error(`Failed to load remote asset "${assetKey}"`)
      }
    } catch (e) {
      yield put(remoteAssetFailed({ key: assetKey, error: e.message }))
    }
  })
}

export default function* remoteAssetsSagas() {
  yield all([fork(watchRegisteredAssetAndFetch), fork(watchRemoteAssetRequested)])
}
