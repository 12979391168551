import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { formValidationErrors } from 'errors/selectors'
import { useState, useEffect } from 'react'
import { FormValidationErrors } from 'errors/reducer'

// This hook replaces the old, ugly way of threading setStatus through sagas
// Instead, server validation errors end up in redux and this hook
// connects redux to formik, within the calling context
export const useServerValidation = <T>() => {
  const validationErrors = useSelector(formValidationErrors)
  const form = useFormikContext<T>()

  const [lastErrors, setLastErrors] = useState<FormValidationErrors>({})

  useEffect(() => {
    // Prevent infinite re-render
    if (lastErrors !== validationErrors) {
      setLastErrors(validationErrors)
      form.setStatus(validationErrors)
    }
  }, [validationErrors, form, lastErrors, setLastErrors])
}
