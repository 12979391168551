import React, { CSSProperties } from 'react'
import './Dot.scss'
import { css, cx } from 'emotion'

type DotColors = 'light' | 'dark'

type Props = {
  isActive?: boolean
  onClick?: () => void
  className?: string
  style?: CSSProperties
  color?: DotColors
}

const colorStyle = (c: DotColors) => css`
  background-color: ${c === 'light'
    ? `rgba(216,216,216,0.33)`
    : `rgba(113,113,113,0.33)`};
`

const Dot = ({ className, onClick, isActive, style, color = 'light' }: Props) => {
  className = cx(
    className,
    'aumo_dot',
    {
      isActive: !!isActive,
    },
    colorStyle(color)
  )

  return <div className={className} onClick={onClick} style={style} />
}

export default Dot
