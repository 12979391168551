import { alintaOrange, bodyFont, whitespace } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import React from 'react'

type Props = {
  creatorName: string
  detailsUrl: string
}

const style = css`
  padding: ${whitespace[2]};
  background: ${alintaOrange};
  font-family: ${bodyFont};

  box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.15);
  position: relative;

  display: flex;
  justify-content: center;
`

const labelStyle = css`
  font-weight: bold;
`

const linkStyle = css`
  color: white;
  display: inline-block;
  background: white;
  border: 1px solid white;
  color: ${alintaOrange};
  border-radius: 2px;
  padding: ${whitespace[1]} ${whitespace[2]};
  font-size: 0.8em;
  font-weight: bold;

  margin-left: ${whitespace[2]};
  text-decoration: none;

  transition: background 0.2s ease, color 0.2s ease;

  &:hover {
    background: ${alintaOrange};
    color: white;
  }

  margin-top: ${whitespace[2]};

  @media screen and (min-width: 450px) {
    margin-top: 0;
  }
`

export const AgentPhoneEicBanner = ({ creatorName, detailsUrl }: Props) => {
  return (
    <div className={cx(style)}>
      <span>
        <label className={cx(labelStyle)}>Authenticated as</label>: {creatorName}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={cx(linkStyle)}
          href={detailsUrl}
        >
          View Connection in Connect Assist ↗
        </a>
      </span>
    </div>
  )
}
