import React from 'react'
import Content from '../Content'
import SpeechBubble from '../SpeechBubble'
import Page from '../Page'
import HeadingText from '../HeadingText'
import BoldText from '../BoldText'
import { handleGenericError } from '../../lib/handleError.ts'
import '../../failure/InvitationExpiredPage.scss'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    console.error(`Caught error`, error, info)
    this.setState({ hasError: true })

    handleGenericError(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Page className="aumo_error-boundary-page">
          <Content>
            <HeadingText>Oops...</HeadingText>
            <SpeechBubble bubbleArrowDirection="">
              <BoldText>
                Sorry, something went wrong :(
                <br /><br />
                Please try refreshing this page.
              </BoldText>
            </SpeechBubble>
          </Content>
        </Page>
      )
    }

    return this.props.children
  }
}
