import { ThemeDefinition, useTheme } from '@cx/connect-ui/lib'
import { css, cx } from 'emotion'
import React, { ReactNode } from 'react'
import './BoldText.scss'
import { useLightBackground } from 'theme'

type Props = {
  className?: string
  dangerouslySetInnerHTML?: {
    __html: string
  }
  children?: ReactNode
}

const style = (theme: ThemeDefinition, lightBg: boolean) => css`
  font-family: ${theme.bodyFont};
  color: ${lightBg ? theme.baseFontColor : 'white'};
`

const BoldText = ({ className, dangerouslySetInnerHTML, children }: Props) => {
  const theme = useTheme()
  const lightBg = useLightBackground()

  return (
    <div
      className={cx(className, 'aumo_bold-text', style(theme, lightBg))}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </div>
  )
}

export default BoldText
