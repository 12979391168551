import * as t from 'io-ts'
import { stepSchema } from './wizardStep'
import { optional } from './validate'
import { moveInAvailability, moveInPicker } from './confirmDetails'

const visualSiteInspectionFormSchema = t.type({
  powerIsCurrentlyOn: optional(t.boolean),
  electricityConnectionDate: t.string,
  appointmentTime: t.string,
  vacantOrOccupied: t.string,
  locationOfKeys: t.string,
  locationOfKeysText: t.string,
  meterAccess: optional(t.boolean),
  keysWillBeAccessible: optional(t.boolean),
  adultWillBePresent: optional(t.boolean),
})

export type VisualSiteInspection = t.TypeOf<typeof visualSiteInspectionFormSchema>

export const visualSiteInspectionSchema = stepSchema({
  // visualSiteInspection: visualSiteInspectionFormSchema,
  moveInDate: t.string,
  moveInAvailability,
  moveInPicker,
  appointmentTimeOptions: t.array(t.type({ label: t.string, value: t.string })),
  locationOfKeysOptions: t.array(t.type({ label: t.string, value: t.string })),
  vacantOrOccupiedOptions: t.array(t.type({ label: t.string, value: t.string })),
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
})

