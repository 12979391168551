import { FormikHelpers } from 'formik'
import * as Option from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import produce from 'immer'
import _omit from 'lodash/omit'
import _startCase from 'lodash/startCase'
import { Action } from 'redux'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { BusinessDetailsState } from 'wizard/state/abrLookup'
import { businessDetailsLookup } from 'wizard/state/selectors'
import {
  formSubmissionFailureCleared,
  formSubmissionFailureEncountered,
  formValidationCleared,
} from '../../errors/actions'
import appInsights from '../../integrations/appInsights'
import { fetchAny, FetchRequest } from '../../lib/api'
import {
  extractErrorCode,
  extractErrorMessage,
  isTopLevelErrorResponse,
} from '../../lib/errors'
import handleError from '../../lib/handleError'
import { isValidationErrorResponse, ValidationError } from '../../lib/validators'
import L from '../../lodashFp'
import {
  confirmIdentityButtonClicked,
  confirmPersonalDetailsButtonClicked,
  confirmVisualSiteInspectionButtonClicked,
  formSubmissionRequestCompleted,
  formSubmissionRequestInitiated,
  submitConcessionsButtonClicked,
  submitConsentClicked,
  submitFeedbackClicked,
  welcomePackDeliveryMethodClicked as postalDeliveryPreferenceButtonClicked,
  confirmSiteAddressButtonClicked,
  confirmSiteAddressSmeButtonClicked,
  confirmNmiButtonClicked,
  confirmBusinessDetailsButtonClicked,
  submitVicSafetyQuestionsButtonClicked,
  submitNswSafetyQuestionsButtonClicked,
} from '../actions'
import { AccountInfo } from '../api/contracts/confirmDetails'
import { CustomerIdentity } from '../api/contracts/confirmIdentity'
import { CustomerFeedback } from '../api/contracts/feedback'
import { PostalDeliveryPreference } from '../api/contracts/postalAddress'
import { VisualSiteInspection } from '../api/contracts/visualSiteInspection'
import * as selectors from '../selectors'
import { autofollow } from './navigationSagas'
import { SiteAddress } from 'wizard/api/contracts/siteAddress'
import { NmiEntry } from 'wizard/api/contracts/nmiEntry'
import { BusinessDetailsInfo } from 'wizard/api/contracts/confirmBusinessDetails'
import { Contact } from 'wizard/api/contracts/wizardStep'
import { showFollowUpQuestions } from 'wizard/steps/safetyQuestions/selectors'
import {
  NswSafetyQuestions,
  VicSafetyQuestions,
} from 'wizard/api/contracts/safetyQuestions'

type FormikSubmissionAction<T> = Action & {
  payload: T
  meta: FormikHelpers<T>
}

function stripEmptyKeys(payload: any) {
  const keys = Object.keys(payload)
  return _omit(
    payload,
    keys.filter((k) => payload[k] === '')
  )
}

export function* submitForm(
  fetchPayload: FetchRequest,
  mutateErrorResFn?: (err: any) => any
) {
  const payload = {
    ...fetchPayload,
    body: stripEmptyKeys(fetchPayload.body),
  }

  yield put(formSubmissionRequestInitiated())
  const res = yield call(fetchAny, payload as any)
  yield put(formSubmissionRequestCompleted())

  yield put(formValidationCleared())
  yield put(formSubmissionFailureCleared())

  if (isTopLevelErrorResponse(res)) {
    yield put(
      formSubmissionFailureEncountered({
        errorCode: extractErrorCode(res),
        message: extractErrorMessage(res),
      })
    )
  }

  if (isValidationErrorResponse(res)) {
    const errorRes = mutateErrorResFn ? mutateErrorResFn(res) : res
    throw new ValidationError(errorRes.errors, errorRes.message)
  }

  return res
}

function* handleConfirmSiteAddressButtonClicked() {
  yield takeLatest(
    confirmSiteAddressButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<SiteAddress>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const body = {
          ...payload,
          saleType: payload.saleType ?? 'Transfer',
          currentAddress:
            payload.saleType === 'MoveIn' && payload.currentAddress?.street
              ? payload.currentAddress
              : null,
        }
        const res = yield call(submitForm, { ...nextAction, body: body })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleConfirmSiteAddressSmeButtonClicked() {
  yield takeLatest(
    confirmSiteAddressSmeButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<SiteAddress>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const body = { ...payload }
        const res = yield call(submitForm, { ...nextAction, body: body })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleConfirmNmiButtonClicked() {
  yield takeLatest(
    confirmNmiButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<NmiEntry>) {
      try {
        const confirmNmiAction = yield select(selectors.getNextAction)
        const checkUpdatedAddressAction = yield select(
          selectors.getCheckUpdatedAddressAction
        )

        const nextAction =
          payload.addressIsCorrect === 'correct'
            ? confirmNmiAction
            : checkUpdatedAddressAction

        const res = yield call(submitForm, {
          ...nextAction,
          body: payload,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

interface IContact {
  contact?: Contact
}

const clearWhitespace = (x: string) => x.replace(/ /g, '')

const removeMobileWhitespace = <T extends IContact>(p: T) =>
  produce(p, (draft) => {
    if (draft.contact) {
      draft.contact.phone = pipe(draft.contact.phone, L.defaultTo(''), clearWhitespace)
    }
  })

const removeAbnAcnWhitespace = <T extends AccountInfo>(p: T) =>
  produce(p, (draft) => {
    if (draft.businessDetails) {
      draft.businessDetails.businessEntity.abn = clearWhitespace(
        draft.businessDetails.businessEntity.abn
      )

      if (draft.businessDetails.trusteeDetails?.trusteeAcn) {
        draft.businessDetails.trusteeDetails.trusteeAcn = clearWhitespace(
          draft.businessDetails.trusteeDetails.trusteeAcn
        )
      }
    }
  })

function* handleConfirmPersonalDetailsButtonClicked() {
  yield takeLatest(
    confirmPersonalDetailsButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<AccountInfo>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const abrLookupResult: BusinessDetailsState = yield select(businessDetailsLookup)

        const removeBusinessDetailsForIndividual = (p: AccountInfo) =>
          produce(p, (draft) => {
            if (draft.customerType === 'individual') {
              draft.businessDetails = undefined
            }
          })

        const body = pipe(
          abrLookupResult.data,
          Option.fold(
            () => payload,
            (data) => ({
              ...payload,
              businessDetails: {
                businessEntity: {
                  abn: data.abn,
                  abnStatus: data.abnStatus,
                  entityName: data.entityName,
                  entityTypeCode: data.entityTypeCode,
                  tradingName: payload.businessDetails?.businessEntity.tradingName,
                },
                trusteeDetails: data.isTrustEntity
                  ? payload.businessDetails?.trusteeDetails
                  : undefined,
              },
            })
          ),
          removeAbnAcnWhitespace,
          removeMobileWhitespace,
          removeBusinessDetailsForIndividual
        )

        const res = yield call(submitForm, {
          ...nextAction,
          body,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleConfirmBusinessDetailsButtonClicked() {
  yield takeLatest(
    confirmBusinessDetailsButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<BusinessDetailsInfo>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const abrLookupResult: BusinessDetailsState = yield select(businessDetailsLookup)

        const body = pipe(
          abrLookupResult.data,
          Option.fold(
            () => payload,
            (data) => ({
              ...payload,
              businessDetails: {
                businessEntity: {
                  abn: data.abn,
                  abnStatus: data.abnStatus,
                  entityName: data.entityName,
                  entityTypeCode: data.entityTypeCode,
                  tradingName: payload.businessDetails?.businessEntity.tradingName,
                },
                trusteeDetails: data.isTrustEntity
                  ? payload.businessDetails?.trusteeDetails
                  : undefined,
              },
            })
          ),
          removeMobileWhitespace
        )

        const res = yield call(submitForm, {
          ...nextAction,
          body,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleConfirmVisualSiteInspectionButtonClicked() {
  yield takeLatest(
    confirmVisualSiteInspectionButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<VisualSiteInspection>) {
      try {
        const nextAction = yield select(selectors.getNextAction)

        const access = {
          type: _startCase(payload.vacantOrOccupied),
          vacant: {
            locationOfKeys:
              payload.locationOfKeys === 'other'
                ? payload.locationOfKeysText
                : payload.locationOfKeys,
            addressCompletelyEmpty: true, // This is an obsolete field. It can be removed after the backend is deployed
            keysWillBeAccessible: payload.keysWillBeAccessible,
          },
          occupied: {
            adultWillBePresent: payload.adultWillBePresent,
          },
        }

        const visualSiteInspection = payload.powerIsCurrentlyOn
          ? { powerIsCurrentlyOn: true }
          : {
              powerIsCurrentlyOn: false,
              electricityConnectionDate: payload.electricityConnectionDate,
              appointmentTime: payload.appointmentTime,
              meterAccess: payload.meterAccess,
              access,
            }

        const res = yield call(submitForm, {
          ...nextAction,
          body: visualSiteInspection,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleConfirmIdentityButtonClicked() {
  yield takeLatest(
    [confirmIdentityButtonClicked.type],
    function* ({ payload }: FormikSubmissionAction<CustomerIdentity>) {
      try {
        const nextAction = yield select(selectors.getNextAction)

        if (payload.identityDocument != null) {
          yield call(appInsights.trackEvent, {
            name: 'identificationMethodChosen',
            properties: {
              identificationMethod: payload.identityDocument.type,
            },
          })
        }

        const res = yield call(submitForm, {
          ...nextAction,
          body: payload,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleCustomerFeedbackButtonClicked() {
  yield takeLatest(
    [submitFeedbackClicked.type],
    function* ({ payload }: FormikSubmissionAction<CustomerFeedback>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const form = payload

        yield call(appInsights.trackEvent, {
          name: 'customerRatingSubmitted',
          properties: {
            rating: form.rating,
            comment: form.comment,
          },
        })

        const res = yield call(submitForm, {
          ...nextAction,
          body: form,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handlePostalDeliveryPreferenceButtonClicked() {
  yield takeLatest(
    postalDeliveryPreferenceButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<PostalDeliveryPreference>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const form = payload

        const res = yield call(submitForm, {
          ...nextAction,
          body: form,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

type ContactDetails = {
  legalName: { firstName: string; lastName: string }
  phone: string
  email: string
}

function* handleSubmitConcessionsButtonClicked() {
  yield takeLatest(
    submitConcessionsButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<any>) {
      try {
        const nextAction = yield select(selectors.getNextAction)

        const data = payload

        const res = yield call(submitForm, {
          ...nextAction,
          body: data,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleSubmitVicSafetyQuestionsButtonClicked() {
  yield takeLatest(
    submitVicSafetyQuestionsButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<VicSafetyQuestions>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const askFollowUpQuestions = yield select(showFollowUpQuestions)

        // Implicitly say no to follow up questions if they aren't asked
        const data = produce(payload, (d) => {
          if (!askFollowUpQuestions) {
            d.recentlyDeEnergised = false
            d.renovationsPlannedOrInProgress = false
          }
        })

        const res = yield call(submitForm, {
          ...nextAction,
          body: data,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleSubmitNswSafetyQuestionsButtonClicked() {
  yield takeLatest(
    submitNswSafetyQuestionsButtonClicked.type,
    function* ({ payload }: FormikSubmissionAction<NswSafetyQuestions>) {
      try {
        const nextAction = yield select(selectors.getNextAction)

        const data = payload

        const res = yield call(submitForm, {
          ...nextAction,
          body: data,
        })
        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleSubmitConsentClicked() {
  yield takeLatest(
    submitConsentClicked.type,
    function* ({ payload }: FormikSubmissionAction<any>) {
      try {
        const nextAction = yield select(selectors.getNextAction)
        const data = payload
        const res = yield call(submitForm, {
          ...nextAction,
          body: data,
        })

        yield call(appInsights.trackEvent, {
          name: submitConsentClicked.type,
        })

        yield call(autofollow, res)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

export default function* root() {
  yield all([
    fork(handlePostalDeliveryPreferenceButtonClicked),
    fork(handleConfirmSiteAddressButtonClicked),
    fork(handleConfirmSiteAddressSmeButtonClicked),
    fork(handleConfirmNmiButtonClicked),
    fork(handleConfirmPersonalDetailsButtonClicked),
    fork(handleConfirmBusinessDetailsButtonClicked),
    fork(handleConfirmVisualSiteInspectionButtonClicked),
    fork(handleConfirmIdentityButtonClicked),
    fork(handleCustomerFeedbackButtonClicked),
    fork(handleSubmitConcessionsButtonClicked),
    fork(handleSubmitVicSafetyQuestionsButtonClicked),
    fork(handleSubmitNswSafetyQuestionsButtonClicked),
    fork(handleSubmitConsentClicked),
  ])
}
