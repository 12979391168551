import { createAction } from '@reduxjs/toolkit'
import { FormikHelpers } from 'formik'
import { PageNavigationDirection } from '../components/util/CustomRoute'
import { Contact } from './api/contracts/wizardStep'
import { CampaignType, AgentPhoneEicDetails } from './reducers'
import { Hypermedia } from './steps/bestDeal/types'

export type FormikSubmissionPayload<T> = {
  values: T
  actions: FormikHelpers<T>
  [key: string]: any
}

const createFormikSubmitAction = (name: string) =>
  createAction(name, ({ values, actions }) => {
    return {
      payload: values,
      meta: actions,
    }
  })

export const linkClicked =
  createAction<{ href: string; showTransition: boolean }>('LINK_CLICKED')
export const pageIsNotFound = createAction<{ notFound: boolean }>('PAGE_IS_NOT_FOUND')
export const enteredPage = createAction<string>('ENTERED_PAGE')
export const pageDataLoaded = createAction<{ key: string; data: any }>('PAGE_DATA_LOADED')
export const pageDataCleared = createAction('PAGE_DATA_CLEARED')
export const pageDataIsPrepared = createAction<boolean>('PAGE_DATA_IS_PREPARED')
export const campaignTypeLoaded = createAction<CampaignType>('CAMPAIGN_TYPE_LOADED')
export const phoneAgentDetailsLoaded = createAction<AgentPhoneEicDetails>(
  'PHONE_AGENT_DETAILS_LOADED'
)

export const pageBackgroundColorChanged = createAction<string>(
  'PAGE_BACKGROUND_COLOUR_CHANGED'
)

export const wizardStepNavigated = createAction<PageNavigationDirection>(
  'WIZARD_STEP_NAVIGATED'
)

export const formSubmissionRequestInitiated = createAction(
  'FORM_SUBMISSION_REQUEST_INITIATED'
)

export const formSubmissionRequestCompleted = createAction(
  'FORM_SUBMISSION_REQUEST_COMPLETED'
)

export const backButtonClicked = createAction<string | undefined>('BACK_BUTTON_CLICKED')
export const skipButtonClicked = createAction<string | undefined>('SKIP_BUTTON_CLICKED')

export const acceptOfferButtonClicked = createAction<{ actionToUse: Hypermedia }>(
  'ACCEPT_BEST_DEAL_BUTTON_CLICKED'
)

export const personalDetailsLoaded = createAction<Contact>('PERSONAL_DETAILS_LOADED')

export const confirmSiteAddressSmeButtonClicked = createFormikSubmitAction(
  'CONFIRM_SITE_ADDRESS_SME_BUTTON_CLICKED'
)

export const confirmSiteAddressButtonClicked = createFormikSubmitAction(
  'CONFIRM_SITE_ADDRESS_BUTTON_CLICKED'
)

export const confirmNmiButtonClicked = createFormikSubmitAction(
  'CONFIRM_NMI_BUTTON_CLICKED'
)

export const confirmPersonalDetailsButtonClicked = createFormikSubmitAction(
  'CONFIRM_PERSONAL_DETAILS_BUTTON_CLICKED'
)

export const confirmBusinessDetailsButtonClicked = createFormikSubmitAction(
  'CONFIRM_BUSINESS_DETAILS_BUTTON_CLICKED'
)

export const confirmVisualSiteInspectionButtonClicked = createFormikSubmitAction(
  'CONFIRM_VISUAL_SITE_INSPECTION_BUTTON_CLICKED'
)

export const welcomePackDeliveryMethodClicked = createFormikSubmitAction(
  'WELCOME_PACK_DELIVERY_METHOD_CLICKED'
)

export const confirmIdentityButtonClicked = createFormikSubmitAction(
  'CONFIRM_IDENTITY_BUTTON_CLICKED'
)

export const submitConcessionsButtonClicked = createFormikSubmitAction(
  'SUBMIT_CONCESSIONS_BUTTON_CLICKED'
)

export const submitVicSafetyQuestionsButtonClicked = createFormikSubmitAction(
  'SUBMIT_VIC_SAFETY_QUESTIONS_BUTTON_CLICKED'
)

export const submitNswSafetyQuestionsButtonClicked = createFormikSubmitAction(
  'SUBMIT_NSW_SAFETY_QUESTIONS_BUTTON_CLICKED'
)

export const submitConsentClicked = createFormikSubmitAction('SUBMIT_CONSENT_CLICKED')

export const submitFeedbackClicked = createFormikSubmitAction('SUBMIT_FEEDBACK_CLICKED')
