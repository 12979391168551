import * as t from 'io-ts'
import { optional } from './validate'

const feedbackFormSchema = t.type({
  rating: optional(t.union([t.literal(1), t.literal(2), t.literal(3), t.literal(4), t.literal(5)])),
  comment: t.string,
})

export const feedbackSchema = t.type({
  text: t.type({
    primary: t.string,
    additional: t.string,
    feedbackPrompt: t.string,
  }),
  advice: optional(t.UnknownRecord),
  _actions: t.UnknownRecord,
  _assets: t.UnknownRecord,
})

export type CustomerFeedback = t.TypeOf<typeof feedbackFormSchema>
