import classNames from 'classnames'
import React, { ReactNode } from 'react'
import './Page.scss'

type Props = {
  className?: string
  children?: ReactNode
  noBackground?: boolean
}

const Page = ({ className, children, noBackground = false }: Props) => {
  const classes = classNames(className, 'aumo_page', {
    'aumo_page--no-background': noBackground,
  })

  return <div className={classes}>{children}</div>
}

export default Page
