import { whitespace } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import React from 'react'
import TwoLogoBanner from './TwoLogoBanner'
import LockupLogoBanner from 'components/LockupLogoBanner'
import AlintaLogoBanner from './AlintaLogoBanner'
import config from 'config'

type Props = {
  partnerLogoUrl: string
  partnerName: string
}

const style = css`
  padding: ${whitespace[3]};
  background: white;

  box-shadow: 0px 3px 20px -5px rgba(0,0,0,0.15);
  position: relative;

  display: flex;
  justify-content: center;
`

const selectLogoBanner = (props: Props) => {
  if (props.partnerName ===  'Cricket Australia')
    return <LockupLogoBanner {...props} />

  if (config.partnersWithoutLogo.includes(props.partnerName.toLowerCase()))
    return <AlintaLogoBanner />

  return <TwoLogoBanner {...props} />
}

export const CoBrandingBanner = (props: Props) => {
  return (
    <div className={cx(style)} data-cy="co-branding-banner" >
      {selectLogoBanner(props)}
    </div>
  )
}
