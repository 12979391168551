import React from 'react'
import cs from 'classnames'
import Page from '../components/Page'
import NotFoundContent from './NotFoundContent'

const NotFoundPage = ({ className }: { className?: string }) => {
  return (
    <Page className={cs(className, 'aumo_not-found-page')}>
      <NotFoundContent />
    </Page>
  )
}

export default NotFoundPage
