import * as t from 'io-ts'
import { businessDetailsSchema } from './business'
import { optional } from './validate'
import { stepSchema, contactSchema, fieldAttributeSchema } from './wizardStep'

export const accountInfoSchema = t.type({
  contact: optional(contactSchema),
  mobileVerificationReceiptId: optional(t.string),
  accountStartDate: optional(t.string),
  businessDetails: optional(businessDetailsSchema),
  customerType: optional(t.union([t.literal('individual'), t.literal('business')])),
})

export const moveInAvailability = t.type({
  invalidStartDates: optional(t.array(t.string)),
  noticePeriodDates: optional(t.array(t.string)),
  nextDayMoveAvailable: optional(t.boolean),
})

export const moveInPicker = fieldAttributeSchema

export type AccountInfo = t.TypeOf<typeof accountInfoSchema>

export const confirmDetailsSchema = stepSchema({
  accountInfo: t.type({
    label: t.string,
    fields: accountInfoSchema,
  }),
  moveInPicker,
  moveInAvailability,
  lockedNameFields: optional(t.boolean),
  text: t.type({
    primary: t.string,
    additional: t.string,
    lockedNameMessage: optional(t.string),
  }),
})
