import { Theme } from '@cx/connect-ui/lib/lib/theme'
import { ThemeActions, CoBrandingPartner } from './actions'

const initialState = {
  activeTheme: 'alinta' as Theme,
  coBrandingPartner: undefined as CoBrandingPartner | undefined,
  callCentreNumber: '13 37 02' as string,
}

export type State = typeof initialState

export default function reducer(state: State = initialState, action: ThemeActions) {
  switch (action.type) {
    case 'ACTIVE_THEME_SET':
      return {
        ...state,
        activeTheme: action.payload,
      }

    case 'COBRANDING_PARTNER_SET':
      return {
        ...state,
        coBrandingPartner: action.payload,
      }

    case 'CALL_CENTRE_NUMBER_SET':
      return {
        ...state,
        callCentreNumber: action.payload,
      }

    default:
      return state
  }
}
