import * as t from 'io-ts'

export const thanksSchema = t.type({
  progress: t.type({
    current: t.string,
    steps: t.array(t.type({ key: t.string, title: t.string }))
  }),
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
  _actions: t.UnknownRecord,
  _assets: t.UnknownRecord,
})
