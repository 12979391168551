import { createReducer, createAction } from '@reduxjs/toolkit'
import { BusinessDetails } from 'wizard/api/contracts/abrLookup'
import { Option, none, some } from 'fp-ts/lib/Option'

export const abrLookupInitiated = createAction<string>('ABR_LOOKUP_INITIATED')
export const abrLookupFailed = createAction<string>('ABR_LOOKUP_FAILED')

export const noAbrResultsResponseReceived = createAction(
  'NO_ABR_RESULTS_RESPONSE_RECEIVED'
)
export const inactiveBusinessAbrResponseReceived = createAction<BusinessDetails>(
  'INACTIVE_BUSINESS_DETAILS_RECEIVED'
)
export const activeBusinessAbrResponseReceived = createAction<BusinessDetails>(
  'ACTIVE_BUSINESS_DETAILS_RECEIVED'
)

export type BusinessLookupState =
  | 'INITIAL'
  | 'LOADING'
  | 'SUCCESS'
  | 'INVALID'
  | 'FAILURE'

const initialState = {
  data: none as Option<BusinessDetails>,
  error: none as Option<string>,
  state: 'INITIAL' as BusinessLookupState,
}

export type BusinessDetailsState = typeof initialState

export const abrLookupReducer = createReducer(initialState, (b) => {
  b.addCase(abrLookupInitiated, (s, _) => {
    s.state = 'LOADING'
  })

  b.addCase(abrLookupFailed, (s, a) => {
    s.state = 'FAILURE'
    s.data = none
    s.error = some(a.payload)
  })

  b.addCase(activeBusinessAbrResponseReceived, (s, a) => {
    s.state = 'SUCCESS'
    s.error = none
    s.data = some(a.payload)
  })

  b.addCase(inactiveBusinessAbrResponseReceived, (s, a) => {
    s.state = 'INVALID'
    s.error = some(a.payload.abnStatus)
    s.data = some(a.payload)
  })

  b.addCase(noAbrResultsResponseReceived, (s, a) => {
    s.state = 'INVALID'
    s.error = some('No results')
    s.data = none
  })
})
