import classNames from 'classnames'
import { css, cx } from 'emotion'
import { motion } from 'framer-motion'
import _ from 'lodash'
import React from 'react'
import { useLightBackground } from 'theme'
import Dot from './Dot'
import plugEndDark from './icons/svg/icon-progress-plug-end-dark.svg'
import plugEndLight from './icons/svg/icon-progress-plug-end.svg'
import plugStartDark from './icons/svg/icon-progress-plug-start-dark.svg'
import plugStartCompleteDark from './icons/svg/icon-progress-plug-start-plugged-in-dark.svg'
import plugStartCompleteLight from './icons/svg/icon-progress-plug-start-plugged-in.svg'
import plugStartLight from './icons/svg/icon-progress-plug-start.svg'
import './ProgressIndicator.scss'
import Sparks from './Sparks'

const plugEnd = (lightBg: boolean) => (lightBg ? plugEndDark : plugEndLight)
const plugStart = (lightBg: boolean) => (lightBg ? plugStartDark : plugStartLight)
const plugStartComplete = (lightBg: boolean) =>
  lightBg ? plugStartCompleteDark : plugStartCompleteLight

type Props = {
  className?: string
  stepCount: number
  currentStep: number
}

const spacing = 38
const darkColor = `rgb(186, 186, 186)`

const lineStyle = (lightBg: boolean) => css`
  background: ${lightBg ? darkColor : 'white'};
  position: absolute;
  height: 6px;
`

const middleSectionWrapperStyle = css`
  display: flex;
  align-items: center;
  position: relative;
`

const sparksStyle = css`
  position: relative;
  left: -20px;
`

const dotStyle = (lightBg: boolean) => css`
  ${lightBg ? `background-color: ${darkColor};` : ''}
`

const sideLineStyle = (lightBg: boolean) => css`
  background: ${lightBg ? darkColor : 'white'};
`

const ProgressIndicator = ({ className, stepCount, currentStep }: Props) => {
  const stepsArray = _.fill(Array(stepCount), undefined)

  const currentStepConstrained = Math.min(Math.max(0, currentStep), stepCount)
  const complete = currentStepConstrained >= stepCount
  const lightBg = useLightBackground()

  return (
    <div className={classNames(className, 'aumo_progress-indicator')}>
      <div
        className={cx(
          'aumo_progress-indicator--line',
          'aumo_progress-indicator--line--left',
          sideLineStyle(lightBg)
        )}
      />
      <motion.div
        className={cx(middleSectionWrapperStyle)}
        animate={{
          x: complete ? -(currentStepConstrained * spacing - 20) / 2 : 0,
        }}
        transition={{
          delay: 0.2,
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.4,
        }}
      >
        <motion.div
          className={cx(lineStyle(lightBg))}
          animate={{
            width: `${currentStepConstrained * spacing + 8}px`,
          }}
        />
        <motion.img
          className="aumo_progress-indicator--plug aumo_progress-indicator--plug--start"
          src={complete ? plugStartComplete(lightBg) : plugStart(lightBg)}
          alt="Plug start icon"
          animate={{
            x: `${currentStepConstrained * spacing - 12}px`,
            scale: complete ? 1.5 : 1,
          }}
        />
        <div className="aumo_progress-indicator--dots">
          {stepsArray.map((step, i) => (
            <Dot
              key={i}
              color={lightBg ? 'dark' : 'light'}
              className={cx(dotStyle(lightBg))}
              style={{ opacity: i + 1 <= currentStepConstrained ? 0 : 1 }}
            />
          ))}
        </div>
        <Sparks className={cx(sparksStyle)} trigger={complete} />
        <motion.img
          className="aumo_progress-indicator--plug aumo_progress-indicator--plug--end"
          src={plugEnd(lightBg)}
          alt="Plug end icon"
          animate={{
            opacity: complete ? 1 : 0.33,
            scale: complete ? 1.5 : 1,
          }}
        />
      </motion.div>
      <motion.div
        className={cx(
          'aumo_progress-indicator--line',
          'aumo_progress-indicator--line--right',
          sideLineStyle(lightBg)
        )}
        transition={{
          delay: 0.2,
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.4,
        }}
        animate={{
          opacity: complete ? 1 : 0.33,
          scaleY: complete ? 1 : 0.8,
          x: complete ? -(currentStepConstrained * spacing - 20) / 2 : 0,
          scaleX: complete ? 3 : 1,
        }}
      />
    </div>
  )
}

export default ProgressIndicator
