import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web'
import config from '../config'
import { ICustomProperties } from '@microsoft/applicationinsights-core-js'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import globalHistory from '../lib/history'

export const aiReactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.appInsightsInstrumentationKey,
    enableAutoRouteTracking: true,
    extensions: [aiReactPlugin],
    extensionConfig: {
      [aiReactPlugin.identifier]: { history: globalHistory },
    },
  },
})

export function isConfigured() {
  return config.appInsightsInstrumentationKey !== ''
}

function initialize() {
  if (isConfigured()) {
    appInsights.loadAppInsights()
  } else {
    console.warn(
      'App Insights is not configured, no telemetry will be reported.'
    )
  }
}

function trackEvent(ev: IEventTelemetry, custom?: ICustomProperties) {
  if (isConfigured()) {
    appInsights.trackEvent(ev, custom)
  }
}

function trackException(ex: IExceptionTelemetry) {
  if (isConfigured()) {
    appInsights.trackException(ex)
  }
}

function setAuthenticatedUserContext(
  authenticatedUserId: string,
  accountId?: string,
  storeInCookie?: boolean
) {
  if (isConfigured()) {
    appInsights.setAuthenticatedUserContext(
      authenticatedUserId,
      accountId,
      storeInCookie
    )
  }
}

export default {
  initialize,
  setAuthenticatedUserContext,
  trackEvent,
  trackException,
  isConfigured,
}
