import { AdditionalInfoForService } from '@cx/connect-ui/lib/components/energy/types'
import { completedSchema } from 'failure/api/contracts/completed'
import * as Option from 'fp-ts/lib/Option'
import _ from 'lodash'
import { call, delay, put, select, spawn } from 'redux-saga/effects'
import { confirmBusinessDetailsSchema } from 'wizard/api/contracts/confirmBusinessDetails'
import { nmiEntrySchema } from 'wizard/api/contracts/nmiEntry'
import {
  nswSafetyQuestionsSchema,
  vicSafetyQuestionsSchema,
} from 'wizard/api/contracts/safetyQuestions'
import { siteAddressSchema, siteAddressSmeSchema } from 'wizard/api/contracts/siteAddress'
import { customerOptInUpdated } from '../../customer/actions'
import {
  customerOptInStatusHasBeenTouched,
  customerRequestedElectricity,
  customerRequestedGas,
} from '../../customer/selectors'
import { apiContractFailureEncountered } from '../../errors/actions'
import { expiredSchema } from '../../failure/api/contracts/expired'
import { notFoundSchema } from '../../failure/api/contracts/notFound'
import { withdrawnSchema } from '../../failure/api/contracts/withdrawn'
import { reportError } from '../../lib/handleError'
import history from '../../lib/history'
import utils from '../../lib/utils'
import L from '../../lodashFp'
import { pageDataLoaded, personalDetailsLoaded } from '../actions'
import { concessionsSchema, medicalNeedsSchema } from '../api/contracts/concessions'
import { confirmDetailsSchema } from '../api/contracts/confirmDetails'
import { confirmIdentitySchema } from '../api/contracts/confirmIdentity'
import { consentSchema } from '../api/contracts/consent'
import { feedbackSchema } from '../api/contracts/feedback'
import { postalDeliveryPreferenceSchema } from '../api/contracts/postalAddress'
import { thanksSchema } from '../api/contracts/thanks'
import { throwIfInvalid } from '../api/contracts/validate'
import { visualSiteInspectionSchema } from '../api/contracts/visualSiteInspection'
import { mapProductAdvice } from '../api/productAdvice'
import {
  bundleInitialStateReceived,
  businessDetailsInitialStateReceived,
  concessionsInitialStateReceived,
  consentInitialStateReceived,
  customerDetailsInitialStateReceived,
  customerIdentityInitialStateReceived,
  nmiEntryInitialStateReceived,
  nswSafetyQuestionsInitialStateReceived,
  postalDeliveryPreferenceInitialStateReceived,
  siteAddressInitialStateReceieved,
  siteAddressSmeInitialStateReceived,
  vicSafetyQuestionsInitialStateReceived,
  visualSiteInspectionInitialStateReceived,
} from '../state/actions'
import * as initialStates from '../state/initialStates'
import {
  BUSINESS_DETAILS,
  CONCESSIONS,
  CONFIRM_DETAILS,
  CONFIRM_IDENTITY,
  CONSENT,
  FEEDBACK,
  INVITATION_COMPLETED,
  INVITATION_EXPIRED,
  INVITATION_WITHDRAWN,
  MEDICAL_NEEDS,
  NMI_ENTRY,
  NOT_FOUND,
  POSTAL_ADDRESS,
  SITE_ADDRESS,
  THANKS,
  VIC_SAFETY_QUESTIONS,
  VISUAL_SITE_INSPECTION,
} from '../steps/pages'
import { NSW_SAFETY_QUESTIONS, SITE_ADDRESS_SME } from './../steps/pages'
import { transformTextForHtml } from './sagas'

export function* handleServerCommunicationError(e: Error) {
  console.error(e)
  yield put(apiContractFailureEncountered(e))
}

function* handleApiContractError(e: Error) {
  console.error(e)
  yield put(apiContractFailureEncountered(e))
  yield call(reportError, `API contract failure`, e)
}

function* storePageData(key: string, data: any) {
  yield put(pageDataLoaded({ key, data }))
}

export function* findBestDealPage(data: any) {
  yield storePageData('findBestDealText', L.get('loadingText')(data))
  yield delay(2000)
}

export function* bestDealPage(data: any) {
  const bundleAction = L.get('_actions.next:dual-fuel')(data)
  const elecAction = L.get('_actions.next')(data)
  const gasAction = L.get('_actions.next:gas')(data)
  const offerQuoteAction = L.get('_actions.deal:request-offer-quote')(data)

  // Account for if the user was already opted-in this session
  // Prevents the checkbox from re-checking itself if the user unchecked, viewed T&C's then hit back
  const requestedGas = yield select(customerRequestedGas)
  const requestedElectricity = yield select(customerRequestedElectricity)
  const currentOptInStatusTouched = yield select(customerOptInStatusHasBeenTouched)

  const productAdvice = L.get('productAdvice')(data)
  if (!productAdvice) {
    throw new Error(
      'Customer is trying to sign up but the Product Advice data is missing'
    )
  }

  // Has the customer already made a selection this session?
  // If so, use their preferences, otherwise check what's available and use that.
  const initialState = currentOptInStatusTouched
    ? { requestedGas, requestedElectricity }
    : { requestedGas: !!gasAction || !!bundleAction, requestedElectricity: !!elecAction }

  yield put(bundleInitialStateReceived({ initialState }))
  yield put(
    customerOptInUpdated({
      gas: initialState.requestedGas,
      electricity: initialState.requestedElectricity,
    })
  )

  yield storePageData('bundleAction', bundleAction)
  yield storePageData('elecAction', elecAction)
  yield storePageData('gasAction', gasAction)
  yield storePageData('offerQuoteAction', offerQuoteAction)
  yield storePageData('bestDealHeading', L.get('text')(data))
  yield storePageData('bestDeal', L.get('deal')(data))
  yield storePageData('mandatoryServices', L.get('mandatoryServices')(data) || [])

  yield storePageData(
    'meterDetailsLookupSucceeded',
    L.get('meterDetailsLookupSucceeded')(data) || false
  )
  yield storePageData('productAdvice', productAdvice.map(mapProductAdvice))
  yield storePageData('tenancy', L.get('tenancy')(data))
  yield storePageData('preConsentStatements', L.get('preConsentStatements')(data))

  const elecOnlyAdditionalInfo: AdditionalInfoForService = {
    services: ['Electricity'],
    additionalInfo: [
      L.get('_links.deal:full-details')(data),
      L.get('_links.deal:more-information')(data),
    ],
  }

  const gasOnlyAdditionalInfo: AdditionalInfoForService = {
    services: ['Gas'],
    additionalInfo: [
      L.get('_links.deal:full-details:gas')(data),
      L.get('_links.deal:more-information:gas')(data),
    ],
  }

  const bundleAdditionalInfo: AdditionalInfoForService = {
    services: ['Electricity', 'Gas'],
    additionalInfo: [
      L.get('_links.deal:full-details:dual-fuel')(data),
      L.get('_links.deal:more-information:dual-fuel')(data),
    ],
  }

  yield storePageData(
    'additionalInfo',
    // filter out any missing hypermedia actions
    [elecOnlyAdditionalInfo, gasOnlyAdditionalInfo, bundleAdditionalInfo].filter(
      (a) => !a.additionalInfo.some((i) => i === undefined)
    )
  )

  yield storePageData('_links', data._links)
}

export function* dealDetailsAndTermsPages(data: any) {
  yield storePageData('pageHeader', L.get('title')(data))
  const deal = L.get('deal')(data)
  const openAccordionTitle = utils.normalizeTitle(history.location.hash)
  yield storePageData('openAccordionTitle', openAccordionTitle)
  yield storePageData('deal', {
    ...deal,
    contentSections: L.map((v: any) => {
      return {
        ...v,
        content: transformTextForHtml(v.content),
      }
    })(deal.contentSections),
  })
  if (openAccordionTitle) {
    yield spawn(function* () {
      yield delay(300)
      yield call(utils.scrollToElem, `#accordion-${openAccordionTitle}`)
    })
  }
}

export function* siteAddressSmePage(data: any) {
  try {
    const payload = throwIfInvalid(siteAddressSmeSchema, SITE_ADDRESS_SME, data)

    yield put(
      siteAddressSmeInitialStateReceived({
        initialState: _.merge(
          {},
          initialStates.siteAddressSme,
          payload.siteAddress.fields
        ),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', { plain: payload.text.additional })
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* siteAddressPage(data: any) {
  try {
    const payload = throwIfInvalid(siteAddressSchema, SITE_ADDRESS, data)

    // const address = payload.siteAddress.fields
    const defaultSaleTypeTexts = {
      MoveIn: "Yes - I'm moving here / I've just moved in",
      Transfer: "No - I'm already living here",
    }

    yield put(
      siteAddressInitialStateReceieved({
        initialState: _.merge({}, initialStates.siteAddress, payload.siteAddress.fields),
      })
    )

    yield storePageData(
      'saleTypeLabel',
      payload.siteAddress.saleTypeLabel || 'Are you moving home?'
    )
    yield storePageData(
      'saleTypeTexts',
      payload.siteAddress.saleTypeTexts || defaultSaleTypeTexts
    )
    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('moveInAvailability', payload.moveInAvailability)
    yield storePageData('moveInPicker', payload.moveInPicker)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
    })
    yield storePageData('fieldAttributes', payload.fieldAttributes)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* nmiEntryPage(data: any) {
  try {
    const payload = throwIfInvalid(nmiEntrySchema, NMI_ENTRY, data)
    yield storePageData(
      'checkUpdatedAddressAction',
      _.get(data, '_actions.update-address')
    )

    yield put(
      nmiEntryInitialStateReceived({
        initialState: _.merge({}, initialStates.nmiEntry, payload.nmiEntry.fields),
      })
    )
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* confirmDetailsPage(data: any) {
  try {
    if (!data.moveInAvailability) {
      data = {
        ...data,
        moveInAvailability: {
          invalidStartDates: data.invalidStartDates,
          noticePeriodDates: data.noticePeriodDates,
        },
      }
    }
    if (!data.moveInPicker) {
      data = {
        ...data,
        moveInPicker: {
          isEditable: data.isAccountStartDateEditable,
          isHidden: data.hideAccountStartDate,
        },
      }
    }

    const payload = throwIfInvalid(confirmDetailsSchema, CONFIRM_DETAILS, data)

    const customerDetails = payload.accountInfo.fields

    if (customerDetails.contact) {
      yield put(personalDetailsLoaded(customerDetails.contact))
    }

    yield put(
      customerDetailsInitialStateReceived({
        initialState: _.merge(
          {},
          initialStates.customerDetailsForConfirmation,
          customerDetails,
          {
            customerType: customerDetails.businessDetails ? 'business' : 'individual',
          }
        ),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('moveInAvailability', payload.moveInAvailability)
    yield storePageData('lockedNameFields', payload.lockedNameFields)
    yield storePageData('lockedNameMessage', payload.text.lockedNameMessage)
    yield storePageData('moveInPicker', payload.moveInPicker)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
      boldText: payload.accountInfo.label,
    })
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* confirmBusinessDetailsPage(data: any) {
  try {
    const payload = throwIfInvalid(confirmBusinessDetailsSchema, BUSINESS_DETAILS, data)
    const businessDetails = payload.businessDetails.fields

    if (businessDetails.contact) {
      yield put(personalDetailsLoaded(businessDetails.contact))
    }

    yield put(
      businessDetailsInitialStateReceived({
        initialState: _.merge(
          {},
          initialStates.businessDetailsForConfirmation,
          businessDetails
        ),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('lockedNameFields', payload.lockedNameFields)
    yield storePageData('lockedNameMessage', payload.text.lockedNameMessage)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
      boldText: payload.businessDetails.label,
    })
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* visualSiteInspectionPage(data: any) {
  try {
    const payload = throwIfInvalid(
      visualSiteInspectionSchema,
      VISUAL_SITE_INSPECTION,
      data
    )

    const vsiForm = {
      electricityConnectionDate: payload.moveInDate,
    }

    yield put(
      visualSiteInspectionInitialStateReceived({
        initialState: _.merge({}, initialStates.visualSiteInspection, vsiForm),
      })
    )

    yield storePageData('timeSlotOptions', payload.appointmentTimeOptions)
    yield storePageData('locationOfKeysOptions', payload.locationOfKeysOptions)
    yield storePageData('vacantOrOccupiedOptions', payload.vacantOrOccupiedOptions)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('moveInAvailability', payload.moveInAvailability)
    yield storePageData('moveInPicker', payload.moveInPicker)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
      // boldText: payload.accountInfo.label,
    })
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* confirmIdentityPage(data: any) {
  try {
    const payload = throwIfInvalid(confirmIdentitySchema, CONFIRM_IDENTITY, data)

    const customerIdentity = payload.identity.fields

    yield put(
      customerIdentityInitialStateReceived({
        initialState: _.merge({}, initialStates.customerIdentity, {
          ...customerIdentity,
        }),
      })
    )
    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
    })
    yield storePageData('bottomInfoText', payload.identity.legalText)
    yield storePageData(
      'identityVerificationConsentText',
      payload.identity.identityVerificationConsentText
    )
    yield storePageData('stateOfIssueItems', payload.stateOfIssueItems)
    yield storePageData('countryOfIssueItems', payload.countryOfIssueItems)
    yield storePageData('lockedNameFields', payload.lockedNameFields)
    yield storePageData('lockedNameMessage', payload.text.lockedNameMessage)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* concessionsPage(data: any) {
  try {
    const payload = throwIfInvalid(concessionsSchema, CONCESSIONS, data)

    // const customerIdentity = payload.identity.fields

    yield put(
      concessionsInitialStateReceived({
        initialState: _.merge({}, initialStates.concessions, payload.concessions),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
    })
    yield storePageData('lifeSupportOptions', [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ])
    yield storePageData('optedInServices', data.optedInServices)
    yield storePageData('medicalCoolingOrHeatingOptions', [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ])
    yield storePageData('concessionCard', {
      authorisationText: payload.text.authorization,
      iUnderstandText: payload.text.acceptance,
      concessionTypeOptions: payload.concessionTypeOptions,
      additionalAuthorizations: payload.text.additionalAuthorizations,
    })
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* medicalNeedsPage(data: any) {
  try {
    const payload = throwIfInvalid(medicalNeedsSchema, MEDICAL_NEEDS, data)

    // const customerIdentity = payload.identity.fields

    yield put(
      concessionsInitialStateReceived({
        initialState: _.merge({}, initialStates.concessions),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
    })
    yield storePageData('lifeSupportOptions', [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ])
    yield storePageData('optedInServices', data.optedInServices)
    yield storePageData('medicalCoolingOrHeatingOptions', [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ])
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}
export function* vicSafetyQuestionsPage(data: any) {
  try {
    const payload = throwIfInvalid(vicSafetyQuestionsSchema, VIC_SAFETY_QUESTIONS, data)

    yield put(
      vicSafetyQuestionsInitialStateReceived({
        initialState: _.merge(
          {},
          initialStates.vicSafetyQuestions,
          payload.safetyQuestions
        ),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
    })
    yield storePageData('showFollowUpQuestions', payload.showFollowUpQuestions)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}
export function* nswSafetyQuestionsPage(data: any) {
  try {
    const payload = throwIfInvalid(nswSafetyQuestionsSchema, NSW_SAFETY_QUESTIONS, data)

    yield put(
      nswSafetyQuestionsInitialStateReceived({
        initialState: _.merge(
          {},
          initialStates.nswSafetyQuestions,
          payload.safetyQuestions
        ),
      })
    )

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', {
      plain: payload.text.additional,
    })
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* consentPage(data: any) {
  try {
    const payload = throwIfInvalid(consentSchema, CONSENT, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))

    yield put(
      consentInitialStateReceived({
        initialState: {
          ...initialStates.consent,
          ..._.omitBy(payload.consent, _.isNil),
        },
      })
    )
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* postalDeliveryPreferencePage(data: any) {
  try {
    const payload = throwIfInvalid(postalDeliveryPreferenceSchema, POSTAL_ADDRESS, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', {
      plain: transformTextForHtml(payload.text.additional),
      boldText: transformTextForHtml(payload.text.bills),
    })
    yield storePageData('_actions', payload._actions)
    yield put(
      postalDeliveryPreferenceInitialStateReceived({
        initialState: {
          ...initialStates.postalDeliveryPreference,
          ...payload.postalDeliveryPreference,
        },
        siteAddress: payload.siteAddress,
      })
    )
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* feedbackPage(data: any) {
  try {
    const payload = throwIfInvalid(feedbackSchema, FEEDBACK, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))
    yield storePageData(
      'feedbackHeading',
      transformTextForHtml(payload.text.feedbackPrompt)
    )
    yield storePageData('advice', payload.advice)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* thanksPage(data: any) {
  try {
    const payload = throwIfInvalid(thanksSchema, THANKS, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* invitationWithdrawnPage(data: any) {
  try {
    const payload = throwIfInvalid(withdrawnSchema, INVITATION_WITHDRAWN, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))
    yield storePageData('links', payload._links)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export const mapLinks = (data: any) =>
  L.map((v: { rel: string; image: string }) =>
    L.flow(
      L.get(v.rel),
      Option.fromNullable,
      Option.fold(
        () => null,
        (link) => ({
          ...link,
          ...v,
        })
      )
    )(_.get(data, '_links') || [])
  )(_.get(data, 'content.links') || [])

export function* invitationExpiredPage(data: any) {
  try {
    const payload = throwIfInvalid(expiredSchema, INVITATION_EXPIRED, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('expiryMessage', payload.expiryMessage)
    yield storePageData('newOfferAction', L.get('_actions.new-offer')(data))

    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))
    yield storePageData('links', payload._links)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* invitationCompletedPage(data: any) {
  try {
    const payload = throwIfInvalid(completedSchema, INVITATION_COMPLETED, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('completionMessage', payload.completionMessage)
    yield storePageData('newOfferAction', L.get('_actions.new-offer')(data))

    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}

export function* notFoundPage(data: any) {
  try {
    const payload = throwIfInvalid(notFoundSchema, NOT_FOUND, data)

    yield storePageData('pageHeader', payload.text.primary)
    yield storePageData('pageBodyText', transformTextForHtml(payload.text.additional))
    yield storePageData('links', payload._links)
  } catch (e) {
    yield call(handleApiContractError, e)
  }
}
