import React, { ReactNode } from 'react'
import './HeadingText.scss'
import { useTheme, ThemeDefinition } from '@cx/connect-ui/lib'
import { cx, css } from 'emotion'
import { useLightBackground } from 'theme'

type Props = {
  className?: string
  children: ReactNode
  onClick?: () => void
}

const headingStyle = (theme: ThemeDefinition, lightBg: boolean) =>
  css`
    font-family: ${theme.titleFont};
    color: ${lightBg ? theme.baseFontColor : 'white'};
  `

const HeadingText = ({ className, children, onClick }: Props) => {
  const theme = useTheme()
  const lightBg = useLightBackground()

  return (
    <h1
      className={cx(className, 'aumo_heading-text', headingStyle(theme, lightBg))}
      onClick={onClick}
    >
      {children}
    </h1>
  )
}

export default HeadingText
