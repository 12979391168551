import { combineReducers } from 'redux'
import appReducers from './wizard/reducers'
import wizardStepReducers from './wizard/state/reducers'
import customerReducers from './customer/reducers'
import themeReducers from './themes/reducers'
import { remoteAssetsReducer } from './remoteAssets/reducer'
import { errorReducer } from './errors/reducer'
import { abrLookupReducer } from 'wizard/state/abrLookup'
import { mobileNumberVerificationReducer } from 'wizard/state/mobileNumberVerification'

export default combineReducers({
  app: appReducers,
  wizardSteps: wizardStepReducers,
  customer: customerReducers,
  remoteAssets: remoteAssetsReducer,
  errors: errorReducer,
  theme: themeReducers,
  abr: abrLookupReducer,
  mobileNumberVerification: mobileNumberVerificationReducer,
})
