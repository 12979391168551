import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import BodyText from '../components/BodyText'
import Content from '../components/Content'
import history from '../lib/history'
import { notFoundContent } from './defaultContent'
import { useBackButton } from './hooks'
import { mapLinkImage } from './icons/icons'
import './InvitationExpiredPage.scss'
import { getPageDetails } from './selectors'
import {
  Panel,
  PanelHeader,
  Spacer,
  Stack,
  Title,
} from '@cx/connect-ui/lib'

const NotFoundContent = () => {
  const { pageHeader, pageBodyText, links } = useSelector(
    getPageDetails({
      pageHeader: notFoundContent.text.primary,
      pageBodyText: notFoundContent.text.additional,
      links: notFoundContent._links,
    })
  )

  const onBackButtonClick = useBackButton()

  return (
    <Content>
      <Spacer height={64} />
      <Panel
        hasShadow
        renderHeader={() => (
          <PanelHeader>
            <Title size="large">{pageHeader}</Title>
          </PanelHeader>
        )}
      >
        <Stack align="center" spacing="large">
          <BodyText dangerouslySetInnerHTML={{ __html: pageBodyText }} />
          <div>
            {_.map(links, (v, k) => {
              if (!v) return null

              return (
                <a
                  key={v.href}
                  className="aumo_not-found-page--link"
                  href={v.href}
                  onClick={(e) => {
                    if (v.rel === 'back' && history.length > 0) {
                      e.preventDefault()
                      onBackButtonClick(v.href)
                    }
                  }}
                >
                  <img src={mapLinkImage(k)} alt={v.title} />
                  <span>{v.title}</span>
                </a>
              )
            })}
          </div>
          <Spacer height={32} />
        </Stack>
      </Panel>
    </Content>
  )
}

export default NotFoundContent
