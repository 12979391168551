import { fetchAny } from 'lib/api'

export type VerficationCodeSentResponse = {
  receiptId: string
}

export function sendVerification(
  nonce: string,
  mobileNumber: string,
  recaptchaToken: string
) {
  return fetchAny<VerficationCodeSentResponse>({
    method: 'POST',
    url: `/verification/mobile-numbers`,
    body: {
      nonce,
      mobileNumber,
      recaptchaToken,
    } as any,
  })
}

export type VerificationCodeConfirmedResponse = {
  status: 'Verified'
}

export function confirmVerificationCode(
  nonce: string,
  receiptId: string,
  verificationCode: string
) {
  return fetchAny({
    method: 'POST',
    url: `/verification/mobile-numbers/receipt-id/`,
    body: {
      nonce,
      receiptId,
      verificationCode,
    } as any,
  })
}
