import { createAction } from '@reduxjs/toolkit'
import { Concessions, MedicalNeeds } from '../api/contracts/concessions'
import { VisualSiteInspection } from '../api/contracts/visualSiteInspection'
import { AccountInfo } from '../api/contracts/confirmDetails'
import { Consent } from '../api/contracts/consent'
import { PostalDeliveryPreference } from '../api/contracts/postalAddress'
import { bundle, customerIdentity } from './initialStates'
import { AddressValue } from '@cx/connect-ui/lib/components/fields/address'
import { BusinessDetails } from 'wizard/api/contracts/abrLookup'
import { SiteAddress, SiteAddressSme } from 'wizard/api/contracts/siteAddress'
import { NmiEntry } from 'wizard/api/contracts/nmiEntry'
import { BusinessDetailsInfo } from 'wizard/api/contracts/confirmBusinessDetails'
import {
  NswSafetyQuestions,
  VicSafetyQuestions,
} from 'wizard/api/contracts/safetyQuestions'

type InitialStatePayload<T, M = {}> = {
  initialState: T
} & M

export const bundleInitialStateReceived = createAction<
  InitialStatePayload<typeof bundle>
>('BUNDLE_INITIAL_STATE_RECEIVED')

export const siteAddressSmeInitialStateReceived = createAction<
  InitialStatePayload<SiteAddressSme>
>('SITE_ADDRESS_SME_INITIAL_STATE_RECEIVED')

export const siteAddressInitialStateReceieved = createAction<
  InitialStatePayload<SiteAddress>
>('SITE_ADDRESS_INITIAL_STATE_RECEIVED')

export const nmiEntryInitialStateReceived = createAction<InitialStatePayload<NmiEntry>>(
  'NMI_ENTRY_INITIAL_STATE_RECEIVED'
)

export const customerDetailsInitialStateReceived = createAction<
  InitialStatePayload<AccountInfo>
>('CUSTOMER_DETAILS_INITIAL_STATE_RECEIVED')

export const businessDetailsInitialStateReceived = createAction<
  InitialStatePayload<BusinessDetailsInfo>
>('BUSINESS_DETAILS_INITIAL_STATE_RECEIVED')

export const abrBusinessDetailsPopulated = createAction<
  BusinessDetails & { tradingName: string }
>('ABR_BUSINESS_DETAILS_POPULATED')

export const customerBusinessDetailsInitialStateReceived = createAction<
  BusinessDetails & { tradingName: string }
>('CUSTOMER_BUSINESS_DETAILS_INITIAL_STATE_RECEIVED')

export const visualSiteInspectionInitialStateReceived = createAction<
  InitialStatePayload<VisualSiteInspection>
>('VISUAL_SITE_INSPECTION_INITIAL_STATE_RECEIVED')

export const customerIdentityInitialStateReceived = createAction<
  InitialStatePayload<typeof customerIdentity>
>('CUSTOMER_IDENTITY_INITIAL_STATE_RECEIVED')

export const concessionsInitialStateReceived = createAction<
  InitialStatePayload<Concessions>
>('CONCESSIONS_INITIAL_STATE_RECEIVED')

export const medicalNeedsInitialStateReceived = createAction<
  InitialStatePayload<MedicalNeeds>
>('MEDICAL_NEEDS_INITIAL_STATE_RECEIVED')

export const vicSafetyQuestionsInitialStateReceived = createAction<
  InitialStatePayload<VicSafetyQuestions>
>('VIC_SAFETY_QUESTIONS_INITIAL_STATE_RECEIVED')

export const nswSafetyQuestionsInitialStateReceived = createAction<
  InitialStatePayload<NswSafetyQuestions>
>('NSW_SAFETY_QUESTIONS_INITIAL_STATE_RECEIVED')

type PostalDeliveryPreferenceInitialStateMetadata = {
  siteAddress?: AddressValue
}

export const postalDeliveryPreferenceInitialStateReceived = createAction<
  InitialStatePayload<
    PostalDeliveryPreference,
    PostalDeliveryPreferenceInitialStateMetadata
  >
>('POSTAL_DELIVERY_PREFERENCE_INITIAL_STATE_RECEIVED')

export const consentInitialStateReceived = createAction<InitialStatePayload<Consent>>(
  'CONSENT_INITIAL_STATE_RECEIVED'
)
