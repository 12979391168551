export type ErrorResponse = {
  error: string
  message: string
  errors: { [id: string]: string[] }
}

export function isTopLevelErrorResponse(res: any) {
  return res && res.error
}

export function extractErrorCode(res: any) {
  return res.error
}

export function extractErrorMessage(res: any) {
  return res.message
}
