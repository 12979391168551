export type Address = {
  fullAddress: string
  unit: string
  number: string
  street: string
  suburb: string
  state: string
  postcode: string
  country: string
}

export const emptyAddress: Address = {
  fullAddress: '',
  unit: '',
  number: '',
  street: '',
  suburb: '',
  postcode: '',
  state: '',
  country: '',
}

type SetFieldValue = (name: string, value: any) => void
type SetFieldTouched = (name: string, value: boolean) => void

export const setAddressComponent = (
  name: string,
  value: any,
  setFieldValue: SetFieldValue,
  setFieldTouched: SetFieldTouched
) => {
  setFieldValue(name, value)
  setFieldTouched(name, true)
}

export const setAddressComponents = (
  name: string,
  address: Address,
  setFieldValue: SetFieldValue,
  setFieldTouched: SetFieldTouched
) => {
  if (!address) {
    return
  }

  setAddressComponent(
    `${name}.fullAddress`,
    address.fullAddress,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.unit`,
    address.unit,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.number`,
    address.number,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.street`,
    address.street,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.suburb`,
    address.suburb,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.state`,
    address.state,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.postcode`,
    address.postcode,
    setFieldValue,
    setFieldTouched
  )
  setAddressComponent(
    `${name}.country`,
    address.country,
    setFieldValue,
    setFieldTouched
  )
}
