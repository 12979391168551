import { fonts, Loader, ThemeContext, ThemeFontInjector } from '@cx/connect-ui'
import { Theme } from '@cx/connect-ui/lib/lib/theme'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { AgentPhoneEicBanner } from 'components/AgentPhoneEicBanner'
import { CoBrandingBanner } from 'components/CoBrandingBanner'
import config from 'config'
import { AnimatePresence, motion } from 'framer-motion'
import { Location } from 'history'
import { ALINTA_STOP_SALE } from 'lib/featureToggles'
import { whenSome } from 'lib/option'
import 'normalize.css'
import React, { lazy, LazyExoticComponent, Suspense } from 'react'
import 'react-dates/initialize'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { pageBackground } from 'wizard/backgroundGradients'
import NmiEntryPage from 'wizard/steps/nmiEntry/NmiEntryPage'
import './App.scss'
import CustomRoute from './components/util/CustomRoute'
import ErrorBoundary from './components/util/ErrorBoundary'
import store from './configureStore'
import appInsights, { aiReactPlugin } from './integrations/appInsights'
import history from './lib/history'
import { activeTheme, coBrandingPartner } from './themes/selectors'
import { appWindow } from './window'
import * as actions from './wizard/actions'
import ProgressIndicator from './wizard/components/ProgressIndicator'
import { agentPhoneEicDetails, getPageBg } from './wizard/selectors'
import * as pages from './wizard/steps/pages'

export const cssTransitionKeyGenerator = ({ key }: { key?: string }) => {
  // A hack to prevent rerendering of the app...
  let show = store.getState().app.pageDataIsPrepared
  if (show) {
    return undefined
  }

  return key
}

history.listen((location, action) => {
  if (action === 'POP') {
    store.dispatch(actions.pageDataIsPrepared(false))
  }
})

// TODO: This is difficult to understand and hacky, I vote to remove
appWindow.handleLinkInPayloadClick = (e) => {
  e.preventDefault()
  e.stopPropagation()
  let absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i')
  let href = e.target.attributes.href.value
  if (href && absoluteUrlRegex.test(href)) {
    const win = appWindow.open(href, e.target.attributes.target || '_self')
    if (win) {
      win.focus()
    }
  } else {
    store.dispatch(actions.linkClicked({ href, showTransition: false }))
  }
}

const NotFoundPage = lazy(() => import('./failure/NotFoundPage'))
const TryLaterPage = lazy(() => import('./failure/TryLaterPage'))

function importWithAppInsights(component: LazyExoticComponent<any>, name: string) {
  if (appInsights.isConfigured()) {
    return withAITracking(aiReactPlugin, component, name)
  } else {
    return component
  }
}

const InvitationEntryPoint = importWithAppInsights(
  lazy(() => import('./wizard/steps/InvitationEntryPoint')),
  'InvitationEntryPoint'
)
const SiteAddressSmePage = importWithAppInsights(
  lazy(() => import('./wizard/steps/siteAddress/SiteAddressSmePage')),
  'SiteAddressSmePage'
)
const SiteAddressPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/siteAddress/SiteAddressPage')),
  'SiteAddressPage'
)
const SearchingForTheBestDealsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/bestDeal/SearchingForTheBestDealsPage')),
  'SearchingForTheBestDealsPage'
)
const BestDealPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/bestDeal/BestDealPage')),
  'BestDealPage'
)
const ConfirmCustomerDetailsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/confirmCustomerDetails/ConfirmCustomerDetailsPage')),
  'ConfirmCustomerDetailsPage'
)
const ConfirmBusinessDetailsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/confirmBusinessDetails/ConfirmBusinessDetailsPage')),
  'ConfirmBusinessDetailsPage'
)
const VisualSiteInspectionPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/visualSiteInspection/VisualSiteInspectionPage')),
  'VisualSiteInspectionPage'
)
const CustomerIdentityPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/customerIdentity/CustomerIdentityPage')),
  'CustomerIdentityPage'
)
const SpecialNeedsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/specialNeeds/SpecialNeedsPage')),
  'SpecialNeedsPage'
)
const VicSafetyQuestionsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/safetyQuestions/VicSafetyQuestionsPage')),
  'VicSafetyQuestionsPage'
)
const NswSafetyQuestionsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/safetyQuestions/NswSafetyQuestionsPage')),
  'NswSafetyQuestionsPage'
)
const WelcomePackSelectionPage = importWithAppInsights(
  lazy(
    () => import('./wizard/steps/postalDeliveryPreference/PostalDeliveryPreferencePage')
  ),
  'WelcomePackSelectionPage'
)
const ThankyouPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/thankyou/ThankyouPage')),
  'ThankyouPage'
)
const FullDetailsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/fullDetails/FullDetailsPage')),
  'FullDetailsPage'
)
const TermsAndConditionsPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/termsAndConditions/TermsAndConditionsPage')),
  'TermsAndConditionsPage'
)
const DonePage = importWithAppInsights(
  lazy(() => import('./wizard/steps/done/DonePage')),
  'DonePage'
)
const ExpiredPage = importWithAppInsights(
  lazy(() => import('./failure/InvitationExpiredPage')),
  'ExpiredPage'
)
const CompletedPage = importWithAppInsights(
  lazy(() => import('./failure/InvitationCompletedPage')),
  'CompletedPage'
)
const ConsentPage = importWithAppInsights(
  lazy(() => import('./wizard/steps/consent/ConsentPage')),
  'ConsentPage'
)
const InvitationWithdrawnPage = importWithAppInsights(
  lazy(() => import('./failure/InvitationWithdrawnPage')),
  'InvitationWithdrawnPage'
)
const AlintaStopSale = importWithAppInsights(
  lazy(() => import('./failure/AlintaStopSalePage')),
  'AlintaStopSalePage'
)

const usePageBg = (theme: Theme) => {
  const pageBg = useSelector(getPageBg)
  if (theme === 'alinta') {
    return pageBackground.alinta
  }

  return pageBg
}

const PageRoutes = ({ location }: { location: Location<any> }) => {
  if (ALINTA_STOP_SALE) {
    return (
      <Switch location={location}>
        <Route exact path="/site-maintenance" component={AlintaStopSale} />
        <Redirect from="*" to="/site-maintenance" />
      </Switch>
    )
  } else {
    return (
      <Switch location={location}>
        {/* <Route exact path="/sandbox" component={SandboxPage} /> */}
        <CustomRoute
          exact
          path="/signup/applications/:applicationId"
          component={InvitationEntryPoint}
          name={pages.INVITATION}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/site-address-sme"
          component={SiteAddressSmePage}
          name={pages.SITE_ADDRESS_SME}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/site-address"
          component={SiteAddressPage}
          name={pages.SITE_ADDRESS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/nmi-entry"
          component={NmiEntryPage}
          name={pages.NMI_ENTRY}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/find-best-deal"
          component={SearchingForTheBestDealsPage}
          name={pages.FIND_BEST_DEAL}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/find-deal"
          component={SearchingForTheBestDealsPage}
          name={pages.FIND_DEAL}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/deal/:dealId"
          component={BestDealPage}
          name={pages.BEST_DEAL}
        />
        <CustomRoute
          path="/signup/applications/:applicationId/deal/:dealId/full-details"
          component={FullDetailsPage}
          name={pages.FULL_DEAL_DETAILS}
        />
        <CustomRoute
          path="/signup/applications/:applicationId/deal/:dealId/terms-and-conditions"
          component={TermsAndConditionsPage}
          name={pages.TERMS_AND_CONDITIONS}
        />
        <CustomRoute
          path="/signup/applications/:applicationId/deal"
          component={BestDealPage}
          name={pages.BEST_DEAL}
        />
        <CustomRoute
          path="/signup/applications/:applicationId/deal-full-details"
          component={FullDetailsPage}
          name={pages.FULL_DEAL_DETAILS}
        />
        <CustomRoute
          path="/signup/applications/:applicationId/deal-terms-and-conditions"
          component={TermsAndConditionsPage}
          name={pages.TERMS_AND_CONDITIONS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/confirm-details"
          component={ConfirmCustomerDetailsPage}
          name={pages.CONFIRM_DETAILS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/business-details"
          component={ConfirmBusinessDetailsPage}
          name={pages.BUSINESS_DETAILS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/safety-questions-qld"
          component={VisualSiteInspectionPage}
          name={pages.VISUAL_SITE_INSPECTION}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/confirm-identity"
          component={CustomerIdentityPage}
          name={pages.CONFIRM_IDENTITY}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/concessions"
          component={SpecialNeedsPage}
          name={pages.CONCESSIONS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/safety-questions"
          component={VicSafetyQuestionsPage}
          name={pages.VIC_SAFETY_QUESTIONS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/nsw-safety-questions"
          component={NswSafetyQuestionsPage}
          name={pages.NSW_SAFETY_QUESTIONS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/medical-needs"
          component={() => <SpecialNeedsPage hideConcessionSection />}
          name={pages.MEDICAL_NEEDS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/consent"
          component={ConsentPage}
          name={pages.CONSENT}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/postal-address"
          component={WelcomePackSelectionPage}
          name={pages.POSTAL_ADDRESS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/feedback"
          component={DonePage}
          name={pages.FEEDBACK}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/thanks"
          component={ThankyouPage}
          name={pages.THANKS}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/expired"
          component={ExpiredPage}
          name={pages.INVITATION_EXPIRED}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/completed"
          component={CompletedPage}
          name={pages.INVITATION_COMPLETED}
        />
        <Route
          exact
          path="/signup/try-again-later/loading-product-offers"
          component={TryLaterPage}
        />
        <CustomRoute
          exact
          path="/signup/applications/:applicationId/withdrawn"
          component={InvitationWithdrawnPage}
          name={pages.INVITATION_WITHDRAWN}
        />
        <CustomRoute component={NotFoundPage} name={pages.NOT_FOUND} />
      </Switch>
    )
  }
}

const App = () => {
  const theme = useSelector(activeTheme)
  const pageBg = usePageBg(theme)
  const partner = useSelector(coBrandingPartner)
  const agentPhoneEic = useSelector(agentPhoneEicDetails)

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaSiteKey}>
          <ThemeContext.Provider value={theme}>
            <ThemeFontInjector theme={theme} fonts={fonts} />
            <Router history={history}>
              <Route
                render={({ location }) => (
                  <div className="aumo_app">
                    {whenSome(agentPhoneEic, (p) => (
                      <AgentPhoneEicBanner
                        detailsUrl={p.detailsUrl}
                        creatorName={p.creatorName}
                      />
                    ))}
                    {!ALINTA_STOP_SALE &&
                      whenSome(partner, (p) => (
                        <CoBrandingBanner
                          partnerLogoUrl={p.logoUrl}
                          partnerName={p.name}
                        />
                      ))}
                    <motion.div
                      className="aumo_body"
                      animate={{ backgroundImage: pageBg }}
                    >
                      <ProgressIndicator />
                      <AnimatePresence>
                        <div className="aumo_route-wrapper">
                          <Suspense fallback={<Loader size="small" />}>
                            <PageRoutes location={location} />
                          </Suspense>
                        </div>
                      </AnimatePresence>
                    </motion.div>
                  </div>
                )}
              />
            </Router>
          </ThemeContext.Provider>
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}

export default App
