import { Loader } from '@cx/connect-ui'
import { css, cx } from 'emotion'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import getPageTitle from 'components/util/getPageTitle'
import NotFoundPage from '../../failure/NotFoundPage'
import { RootState } from '../../state'
import { enteredPage } from '../../wizard/actions'
import { getPageDataIsPrepared, getPageIsNotFound } from '../../wizard/selectors'
import {
  PageName,
  FULL_DEAL_DETAILS,
  TERMS_AND_CONDITIONS,
} from '../../wizard/steps/pages'
import { useLightBackground } from 'theme'

type Props = {
  name: PageName
  component: any
  className?: string
  exact?: boolean
  path?: string
}

export type PageNavigationDirection = 'none' | 'forward' | 'backward'

const loaderStyle = css`
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function navigationDirectionToOffset(dir: PageNavigationDirection) {
  switch (dir) {
    case 'forward':
      return 64
    case 'backward':
      return -64
    case 'none':
      return 0
  }
}

const mapLoaderColor = (name: string, lightBg: boolean) => {
  if (lightBg) {
    return 'grey'
  }

  switch (name) {
    case FULL_DEAL_DETAILS:
    case TERMS_AND_CONDITIONS:
      return 'grey'
  }

  return 'white'
}

const CustomRoute = ({ name, component, ...rest }: Props) => {
  const title = useMemo(() => getPageTitle(name), [name])
  const dispatch = useDispatch()
  const show = useSelector(getPageDataIsPrepared)
  const pageIsNotFound = useSelector(getPageIsNotFound)
  const navigationDirection = useSelector(
    (x: RootState) => x.app.wizardNavigationDirection
  )

  useEffect(() => {
    dispatch(enteredPage(name))
  }, [dispatch, name])

  const RenderingComponent = pageIsNotFound ? NotFoundPage : component

  const lightBg = useLightBackground()

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Route
        {...rest}
        render={(props) => (
          <>
            {!show && (
              <div className={cx(loaderStyle)}>
                <Loader color={mapLoaderColor(name, lightBg)} size="medium" />
              </div>
            )}
            {show && (
              <motion.div
                key={name}
                transition={{
                  type: 'spring',
                  damping: 30,
                  stiffness: 500,
                }}
                initial={{
                  opacity: 0,
                  x: navigationDirectionToOffset(navigationDirection),
                }}
                animate={{ opacity: 1, zIndex: 1, x: 0 }}
                exit={{
                  opacity: 0,
                  zIndex: 0,
                  x: -navigationDirectionToOffset(navigationDirection),
                }}
              >
                <RenderingComponent {...props} />
              </motion.div>
            )}
          </>
        )}
      />
    </>
  )
}

export default CustomRoute
