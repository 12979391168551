import {
  remoteAssetRegistered,
  remoteAssetRequested,
  remoteAssetLoaded,
  remoteAssetFailed,
} from './actions'
import { createReducer } from '@reduxjs/toolkit'

type RemoteAssetState = 'REGISTERED' | 'LOADING' | 'READY' | 'FAILED'

type RemoteAsset<T> = {
  state: RemoteAssetState
  error?: string
  data?: T
}

const initialState = {
  remoteAssetHrefs: {} as { [id: string]: string }, // Where to get an asset from
  remoteAssets: {} as { [id: string]: RemoteAsset<any> }, // Actual asset payload, if loaded
}

export type RemoteAssetsState = typeof initialState

export const remoteAssetsReducer = createReducer(initialState, (b) => {
  b.addCase(remoteAssetRegistered, (s, { payload: { key, href } }) => {
    s.remoteAssetHrefs[key] = href
    s.remoteAssets[key] = {
      state: 'REGISTERED',
    }
  })

  b.addCase(remoteAssetRequested, (s, { payload }) => {
    if (!s.remoteAssetHrefs[payload]) {
      return s
    }

    s.remoteAssets[payload] = {
      state: 'LOADING',
    }
  })

  b.addCase(remoteAssetLoaded, (s, { payload: { key, data } }) => {
    s.remoteAssets[key] = {
      data,
      state: 'READY',
    }
  })

  b.addCase(remoteAssetFailed, (s, { payload: { key, error } }) => {
    s.remoteAssets[key] = {
      error,
      state: 'FAILED',
    }
  })
})
