import { fork, all } from 'redux-saga/effects'
import appSagas from './wizard/sagas/sagas'
import integrationsSagas from './integrations/sagas'
import remoteAssetsSagas from './remoteAssets/sagas'
import themeSagas from './themes/sagas'

export default function* root() {
  yield all([
    fork(appSagas),
    fork(integrationsSagas),
    fork(remoteAssetsSagas),
    fork(themeSagas),
  ])
}
