import * as t from 'io-ts'
import { optional } from './validate'

const trusteeDetailsSchema = t.type({
  trusteeCategory: t.union([t.literal('Company'), t.literal('Individual')]),
  trusteeEntityName: optional(t.string),
  trusteeAcn: optional(t.string),
})

export type TrusteeDetailsT = t.TypeOf<typeof trusteeDetailsSchema>

const businessEntitySchema = t.type({
  tradingName: optional(t.string), // Will stop being optional once ABR_LOOKUP is gone
  entityTypeCode: t.string,
  abn: t.string,
})

export const businessDetailsSchema = t.type({
  businessEntity: businessEntitySchema,
  trusteeDetails: optional(trusteeDetailsSchema),
})
