import { Padding, Stack, Text } from '@cx/connect-ui/lib'
import React from 'react'

const NmiNotFoundText = () => {
  return (
    <Padding>
      <Stack align="center" spacing="generous">
        <Text>
          Looks like we'll need more information to find your NMI. <strong>Chat 
            to one of our friendly specialist who can help with finding your NMI and set you up with a great business deal.
        </strong>
        </Text>
      </Stack>
    </Padding>
  )
}

export default NmiNotFoundText
