import { ErrorPanel } from '@cx/connect-ui'
import React from 'react'
import { useSelector } from 'react-redux'
import { hasApiContractFailure } from '../selectors'

type Props = {
  className?: string
}

const ApiContractFailureErrorPanel = ({ className }: Props) => {
  const error = useSelector(hasApiContractFailure)

  return (
    <>
      {error && (
        <ErrorPanel
          className={className}
          message="An error occurred while communicating with the server. Our team has been notified and will address the issue as soon as possible. In the meantime you can try refreshing your browser."
          action={{
            label: 'Reload page',
            onClick: () => window.location.reload(),
          }}
        />
      )}
    </>
  )
}

export default ApiContractFailureErrorPanel
