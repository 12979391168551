import * as t from 'io-ts'

export const completedSchema = t.type({
  completionMessage: t.string,
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
  _actions: t.UnknownRecord,
  _assets: t.UnknownRecord,
})

export type Completed = t.TypeOf<typeof completedSchema>
