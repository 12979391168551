import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  confirmVerificationCode,
  sendVerification,
  VerficationCodeSentResponse,
  VerificationCodeConfirmedResponse,
} from 'wizard/api/client/mobileVerification'
import {
  mobileNumberVerificationAttempted,
  mobileNumberVerificationFailed,
  mobileNumberVerificationSucceeded,
  verificationCodeFailedToSend,
  verificationCodeRequested,
  verificationCodeSent,
} from 'wizard/state/mobileNumberVerification'
import { mobileNumberVerification } from 'wizard/state/selectors'
import rollbar from 'integrations/rollbar'
import { HttpError } from 'lib/api'

function generateNonce() {
  let array = new Uint32Array(1)
  window.crypto.getRandomValues(array)

  return array[0].toString()
}

function* handleVerificationCodeRequested() {
  yield takeLatest(
    verificationCodeRequested.type,
    function* ({
      payload: { mobile, reCaptchaToken },
    }: ReturnType<typeof verificationCodeRequested>) {
      // Hm, this doens't seem like the best place for this logic
      const formattedNumber = mobile.startsWith('0')
        ? mobile.replace('0', '+61')
        : mobile

      const nonce = generateNonce()

      try {
        const res: VerficationCodeSentResponse = yield call(
          sendVerification,
          nonce,
          formattedNumber.replace(/\s/g, ''),
          reCaptchaToken
        )

        if (res.receiptId) {
          // insert the receiptId & nonce to the redux store
          yield put(verificationCodeSent({ nonce, receiptId: res.receiptId }))
        } else {
          rollbar.info('Server failed to send verification code to mobile')
          yield put(verificationCodeFailedToSend())
        }
      } catch (e) {
        if(e instanceof HttpError){
          rollbar.error('Server failed to send verification code to mobile', e)
          yield put(verificationCodeFailedToSend())
        } else {
          rollbar.error('Client failed to send verification code to mobile', e)
          yield put(verificationCodeFailedToSend())
        }
      }
    }
  )
}

function* handleMobileNumberVerificationAttempted() {
  yield takeLatest(
    mobileNumberVerificationAttempted.type,
    function* ({
      payload: { code, onSuccess },
    }: ReturnType<typeof mobileNumberVerificationAttempted>) {
      // grab the receiptId & nonce from the redux store
      const { nonce, receiptId } = yield select(mobileNumberVerification)

      try {
        let res: VerificationCodeConfirmedResponse = yield call(
          confirmVerificationCode,
          nonce,
          receiptId,
          code
        )

        if (res.status === 'Verified') {
          yield put(mobileNumberVerificationSucceeded())
          yield call(onSuccess, receiptId)
        } else {
          rollbar.warning(
            'Server failed to verify mobile number - incorrect value was provided by the user',
            res
          )
          yield put(mobileNumberVerificationFailed())
        }
      } catch (e) {
        rollbar.error('Client failed to verify mobile number', e)
        yield put(mobileNumberVerificationFailed())
      }
    }
  )
}

export default function* root() {
  yield all([
    fork(handleVerificationCodeRequested),
    fork(handleMobileNumberVerificationAttempted),
  ])
}
