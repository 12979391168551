import { getANewOfferButtonClicked } from 'failure/actions'
import { getNewOfferAction } from 'failure/selectors'
import { whenSome } from 'lib/option'
import _ from 'lodash'
import { Action } from 'redux'
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects'
import { fetchAny, HttpError, loadHypermedia } from '../../lib/api'
import handleError from '../../lib/handleError'
import history from '../../lib/history'
import { registerRemoteAssets } from '../../remoteAssets/sagas'
import { appWindow } from '../../window'
import {
  acceptOfferButtonClicked,
  enteredPage,
  pageBackgroundColorChanged,
  pageDataIsPrepared,
  pageDataLoaded,
  pageIsNotFound,
} from '../actions'
import { pageBackground } from '../backgroundGradients'
import md from '../md'
import * as selectors from '../selectors'
import * as pages from '../steps/pages'
import abrLookupSagas from './abrLookupSagas'
import mobileNumberVerificationSagas from './mobileNumberVerificationSagas'
import offerQuoteSagas from './offerQuoteSagas'
import formSubmissionSagas from './formSubmissionSagas'
import navigationSagas, { autofollow } from './navigationSagas'
import {
  bestDealPage,
  concessionsPage,
  confirmDetailsPage,
  confirmIdentityPage,
  consentPage,
  dealDetailsAndTermsPages,
  feedbackPage,
  findBestDealPage,
  invitationCompletedPage,
  invitationExpiredPage,
  invitationWithdrawnPage,
  medicalNeedsPage,
  notFoundPage,
  postalDeliveryPreferencePage,
  siteAddressSmePage,
  siteAddressPage,
  thanksPage,
  visualSiteInspectionPage,
  nmiEntryPage,
  confirmBusinessDetailsPage,
  vicSafetyQuestionsPage,
  nswSafetyQuestionsPage,
} from './pageSagas'

type PayloadAction<T> = Action & {
  payload: T
}

export function transformTextForHtml(str: string) {
  let hrefRegex = /(?:href\s*=\s*)(("[^"]*.)|('[^']*.))/g
  str = str.replace(hrefRegex, 'onClick="handleLinkInPayloadClick(event)" $&')
  return md.render(str)
}

function* getData() {
  const { pathname: currentPath, search: queryString } = _.get(history, 'location')
  try {
    return yield call(loadHypermedia, `${currentPath}${queryString}`)
  } catch (e) {
    throw e
  }
}

function* storePageData(key: string, data: any) {
  yield put(pageDataLoaded({ key, data }))
}

export function* storeDataByPageName(data: any, pageName: pages.PageName) {
  yield storePageData('clientLogo', _.get(data, 'client.logo'))
  yield storePageData('progress', _.get(data, 'progress'))
  yield storePageData('nextAction', _.get(data, '_actions.next'))
  yield storePageData('backLink', _.get(data, '_links.back'))
  yield storePageData('skipLink', _.get(data, '_links.skip'))

  const autoFollowing = yield call(autofollow, data)
  if (autoFollowing) return

  yield call(registerRemoteAssets, data)

  switch (pageName) {
    case pages.FIND_BEST_DEAL:
      return yield call(findBestDealPage, data)

    case pages.BEST_DEAL:
      return yield call(bestDealPage, data)

    case pages.FULL_DEAL_DETAILS:
    case pages.TERMS_AND_CONDITIONS:
      return yield call(dealDetailsAndTermsPages, data)

    case pages.SITE_ADDRESS_SME:
      return yield call(siteAddressSmePage, data)

    case pages.SITE_ADDRESS:
      return yield call(siteAddressPage, data)

    case pages.NMI_ENTRY:
      return yield call(nmiEntryPage, data)

    case pages.CONFIRM_DETAILS:
      return yield call(confirmDetailsPage, data)

    case pages.BUSINESS_DETAILS:
      return yield call(confirmBusinessDetailsPage, data)

    case pages.VISUAL_SITE_INSPECTION:
      return yield call(visualSiteInspectionPage, data)

    case pages.CONFIRM_IDENTITY:
      return yield call(confirmIdentityPage, data)

    case pages.CONCESSIONS:
      return yield call(concessionsPage, data)

    case pages.MEDICAL_NEEDS:
      return yield call(medicalNeedsPage, data)

    case pages.VIC_SAFETY_QUESTIONS:
      return yield call(vicSafetyQuestionsPage, data)

    case pages.NSW_SAFETY_QUESTIONS:
      return yield call(nswSafetyQuestionsPage, data)

    case pages.CONSENT:
      return yield call(consentPage, data)

    case pages.POSTAL_ADDRESS:
      return yield call(postalDeliveryPreferencePage, data)

    case pages.FEEDBACK:
      return yield call(feedbackPage, data)

    case pages.THANKS:
      return yield call(thanksPage, data)

    case pages.INVITATION_WITHDRAWN:
      return yield call(invitationWithdrawnPage, data)

    case pages.INVITATION_EXPIRED:
      return yield call(invitationExpiredPage, data)

    case pages.INVITATION_COMPLETED:
      return yield call(invitationCompletedPage, data)

    case pages.NOT_FOUND:
      return yield call(notFoundPage, data)
  }
}

export function* handleNotFound() {
  yield put(pageDataIsPrepared(false))
  const data = yield call(loadHypermedia, '/signup/not-found')
  yield call(storeDataByPageName, data, 'not-found')
  yield put(pageDataIsPrepared(true))
  yield put(pageIsNotFound({ notFound: true }))
}

function* handleEnteredPage() {
  yield takeLatest(
    enteredPage.type,
    function* ({ payload: pageName }: PayloadAction<pages.PageName>) {
      try {
        // We can be not-found-ing without an error
        if (pageName === 'not-found') {
          yield call(handleNotFound)
        } else {
          yield put(pageIsNotFound({ notFound: false }))
          const data = yield getData()
          yield call(storeDataByPageName, data, pageName)
          yield put(pageDataIsPrepared(true))
        }
      } catch (e) {
        console.warn('Failed to load data for page ', pageName)
        const probablyOnRootRoute = pageName === 'not-found' && e.status === 401
        if (e instanceof HttpError && (e.status === 404 || probablyOnRootRoute)) {
          yield call(handleNotFound)
        }
        yield call(handleError, e)
        yield put(pageDataIsPrepared(true))
      }
    }
  )
}

export function* acceptOffer({ payload }: any) {
  try {
    // TODO - cleanup
    // const data = yield select(selectors.getPageData)
    // const productKey =
    //   _.get(data, 'bestDeal.productKey') || _.get(data, 'deal.productKey')
    const acceptAction = payload.actionToUse || (yield select(selectors.getNextAction))
    const body =
        acceptAction.body !== undefined && acceptAction.body.trim() !== ''
        ? JSON.parse(acceptAction.body): {}

    let res = yield call(fetchAny, { ...acceptAction, body})

    yield call(autofollow, res)
  } catch (e) {
    yield call(handleError, e)
  }
}

function* handleAcceptOfferButtonClicked() {
  yield takeLatest(acceptOfferButtonClicked.type, acceptOffer)
}

function* handlePageBackgroundColor() {
  yield takeLatest(
    enteredPage.type,
    function* ({ payload: pageName }: PayloadAction<pages.PageName>) {
      switch (pageName) {
        case pages.FIND_DEAL:
        case pages.FIND_BEST_DEAL:
        case pages.BEST_DEAL:
          yield put(pageBackgroundColorChanged(pageBackground.blueGradient))
          break
        case pages.THANKS:
        case pages.FEEDBACK:
          yield put(pageBackgroundColorChanged(pageBackground.aquaGradient))
          break
        case pages.NOT_FOUND:
        case pages.TRY_AGAIN_LATER:
        case pages.INVITATION_EXPIRED:
        case pages.INVITATION_WITHDRAWN:
          yield put(pageBackgroundColorChanged(pageBackground.yellowGradient))
          break
        case pages.FULL_DEAL_DETAILS:
        case pages.TERMS_AND_CONDITIONS:
          yield put(pageBackgroundColorChanged(pageBackground.grey))
          break
        default:
          yield put(pageBackgroundColorChanged(pageBackground.pinkGradient))
      }
    }
  )
}

function* handleWizardStepNavigated() {
  yield takeLatest(pageDataIsPrepared.type, function* ({ payload: { prepared } }: any) {
    if (prepared) {
      yield delay(50)
      yield call(() => {
        appWindow.scrollTo({
          top: -5000,
          behavior: 'auto',
        })
      })
    }
  })
}

function* handleNewOfferRequested() {
  yield takeLatest(getANewOfferButtonClicked.type, function* () {
    const newOfferAction = yield select(getNewOfferAction)

    whenSome(newOfferAction, (a: any) => {
      const { href } = a
      window.location.href = href
    })
  })
}

export default function* root() {
  yield all([
    fork(handleEnteredPage),
    fork(handleAcceptOfferButtonClicked),
    fork(navigationSagas),
    fork(formSubmissionSagas),
    fork(abrLookupSagas),
    fork(mobileNumberVerificationSagas),
    fork(offerQuoteSagas),
    fork(handleWizardStepNavigated),
    fork(handlePageBackgroundColor),
    fork(handleNewOfferRequested),
  ])
}
