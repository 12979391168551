import * as t from 'io-ts'
import { stepSchema, addressSchema } from './wizardStep'
import { optional } from './validate'

export const postalAddressFormSchema = t.type({
  address: optional(addressSchema),
  sameAsSiteAddress: t.boolean,
})

export type PostalDeliveryPreference = t.TypeOf<typeof postalAddressFormSchema>

export const postalDeliveryPreferenceSchema = stepSchema({
  postalDeliveryPreference: postalAddressFormSchema,
  siteAddress: optional(addressSchema),
  text: t.type({
    primary: t.string,
    additional: t.string,
    bills: t.string,
  }),
})
