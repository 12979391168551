import _ from 'lodash'

const Utils = {
  toLowerCase: (str: string) => {
    if (!str) return str
    return str.toLowerCase()
  },
  areEqualCaseInsensitive: (str1: string, str2: string) => Utils.toLowerCase(str1) === Utils.toLowerCase(str2),
  normalizeTitle: (str: string) => {
    let a = _.replace(str, /[#-\s]/g, '').toLowerCase()
    return a
  },
  scrollToElem(elemOrSelector: string | Element) {
    let element: string | Element | null = elemOrSelector

    if (typeof element === 'string') {
      element = document.querySelector(element)
    }

    if (!element) return false

    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
  },
  formatGuid: (str: string) => {
    if (str == null) return str

    str = str.replace(/-/g, '')
    return `${str.substring(0, 8)}-${str.substring(8, 12)}-${str.substring(12, 16)}-${str.substring(16, 20)}-${str.substring(20, str.length)}`
  }
}

export default Utils
