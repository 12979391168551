import classNames from 'classnames'
import React, { useCallback } from 'react'
import ChevronRight from '../../components/icons/ChevronRightIcon'
import './NextButton.scss'
import { PrimaryButton } from '@cx/connect-ui'
import { cx, css } from 'emotion'
import { useDispatch, useSelector } from 'react-redux'
import { wizardStepNavigated } from '../actions'
import { getFormIsSubmitting } from '../selectors'

// TODO: get type information sorted?
export default function NextButton({
  className,
  children,
  onClick,
  hasChevron = true,
  color = 'primaryBlue',
  ...others
}: any) {
  const d = useDispatch()
  const onNextClicked = useCallback(() => {
    d(wizardStepNavigated('forward'))
    onClick()
  }, [d, onClick])

  const isLoading = useSelector(getFormIsSubmitting)

  return (
    <PrimaryButton
      className={classNames(className, 'aumo_next-button')}
      state={isLoading ? 'loading' : 'default'}
      color={color}
      renderRight={
        hasChevron
          ? () => (
              <ChevronRight
                className={cx(
                  css`
                    width: 32px;
                  `
                )}
              />
            )
          : undefined
      }
      onClick={onNextClicked}
      {...others}
    >
      {children}
    </PrimaryButton>
  )
}
