import _get from 'lodash/get'
import { all, call, fork, takeLatest } from 'redux-saga/effects'
import { personalDetailsLoaded, pageDataIsPrepared } from '../wizard/actions'
import handleError from '../lib/handleError'
import rollbar from './rollbar'
import { Action } from 'redux'
import { AccountInfo } from '../wizard/api/contracts/confirmDetails'
import appInsights from './appInsights'

const getInviteGuid = () => {
  const splitPath = window.location.pathname.split('/')
  const inviteGuid = splitPath[3]
  if (!inviteGuid) return
  return inviteGuid
}

type PersonalDetailsLoaded = Action & {
  payload: {
    details: AccountInfo['contact']
  }
}

function* identifyUser() {
  yield takeLatest(
    personalDetailsLoaded.type,
    function* ({ payload: { details } }: PersonalDetailsLoaded) {
      try {
        const id = getInviteGuid()
        const name = `${_get(details, 'legalName.firstName')} ${_get(
          details,
          'legalName.lastName'
        )}`
        const email = _get(details, 'email')

        rollbar.configure({ payload: { person: { id, name, email } } })

        if (id) {
          appInsights.setAuthenticatedUserContext(id, email, true)
        }
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* registerIdOnly() {
  yield takeLatest(pageDataIsPrepared.type, function* () {
    try {
      const id = getInviteGuid()

      rollbar.configure({ payload: { person: { id } } })
      if (id) {
        appInsights.setAuthenticatedUserContext(id, undefined, true)
      }
    } catch (e) {
      console.log('e', e)
      yield call(handleError, e)
    }
  })
}

export default function* userIdentificationSagas() {
  yield all([fork(identifyUser), fork(registerIdOnly)])
}
