import { css, cx } from 'emotion'
import React, { ReactNode } from 'react'
import ApiContractFailureErrorPanel from '../errors/components/ApiContractFailureErrorPanel'
import FormSubmissionFailureErrorPanel from '../errors/components/FormSubmissionFailureErrorPanel'
import './Foreground.scss'
import { useLightBackground } from 'theme'

const Blip = () => (
  <svg viewBox="0 0 8 5" className="aumo_foreground--blip">
    <polygon points="0 0, 4 5, 8 0" />
  </svg>
)

const errorHolderStyle = css`
  margin-bottom: 32px;
`

type Props = {
  isSpeechBubble?: boolean
  wide?: boolean
  children?: ReactNode
  showFormSubmissionFailureError?: boolean
  className?: string
}

const shadowStyle = (lightBg: boolean) => css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, ${lightBg ? 0.1 : 0.5});
`

const Foreground = ({
  isSpeechBubble = false,
  wide = false,
  showFormSubmissionFailureError = true,
  className,
  children,
}: Props) => {
  const lightBg = useLightBackground()
  const classNames = cx(
    'aumo_foreground',
    { 'aumo_foreground--wide': wide },
    className,
    shadowStyle(lightBg)
  )
  return (
    <div className={classNames}>
      <>
        {/* If we find ourselves making another one of these then a
          refactor to a more generalised system makes sense */}
        <ApiContractFailureErrorPanel />
        {showFormSubmissionFailureError ? <FormSubmissionFailureErrorPanel className={cx(errorHolderStyle)} /> : null}
      </>
      {children}
      {isSpeechBubble && <Blip />}
    </div>
  )
}

export default Foreground
