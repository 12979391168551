import React from 'react'
import classNames from 'classnames'
import './HashIcon.scss'

type Props = {
  className?: string
}

export default ({ className }: Props) => {
  return (
    <svg
      className={classNames(className, 'aumo_icon', 'aumo_icon-hash')}
      viewBox="0 0 8 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g>
          <path d="M5.954,4.748 L5.772,6.048 L6.877,6.048 L6.877,7.4 L5.603,7.4 L5.213,10 L3.783,10 L4.16,7.4 L3.133,7.4 L2.756,10 L1.326,10 L1.703,7.4 L0.598,7.4 L0.598,6.048 L1.885,6.048 L2.08,4.748 L0.975,4.748 L0.975,3.396 L2.249,3.396 L2.626,0.796 L4.069,0.796 L3.692,3.396 L4.706,3.396 L5.083,0.796 L6.513,0.796 L6.149,3.396 L7.241,3.396 L7.241,4.748 L5.954,4.748 Z M3.497,4.748 L3.328,6.048 L4.342,6.048 L4.524,4.748 L3.497,4.748 Z" />
        </g>
      </g>
    </svg>
  )
}
