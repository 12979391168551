import { RootState } from '../state'
import { AdditionalConcessionAuthorization } from './api/contracts/concessions'
import { Service } from '@cx/connect-ui/lib/components/energy/types'
import _ from 'lodash'
import { fromNullable } from 'fp-ts/lib/Option'

export const getNextAction = (state: RootState) => state.app.data.nextAction
export const getElecAction = (state: RootState) => state.app.data.elecAction
export const getBundleAction = (state: RootState) => state.app.data.bundleAction
export const getCheckUpdatedAddressAction = (state: RootState) =>
  state.app.data.checkUpdatedAddressAction
export const getBackLink = (state: RootState) => state.app.data.backLink
export const getSkipLink = (state: RootState) => state.app.data.skipLink
export const getPageData = (state: RootState) => state.app.data

export const getPageIsNotFound = (state: RootState) => state.app.pageIsNotFound
export const getPageDataIsPrepared = (state: RootState) => state.app.pageDataIsPrepared
export const getFieldAttribute = (state: RootState) => (name: string) =>
  _.get(state.app.data.fieldAttributes, name, { isEditable: true, isHidden: false })

export const getAccountStartDate = (state: RootState) =>
  state.wizardSteps.customerDetails.initialState.accountStartDate

export const getAccountStart = (state: RootState) => state.app.data.moveInPicker || {}

export const getMoveInAvailability = (state: RootState) =>
  state.app.data.moveInAvailability || {}

export const getLockedNameFields = (state: RootState) => state.app.data.lockedNameFields
export const getLockedNameMessage = (state: RootState) => state.app.data.lockedNameMessage

export const getPageContent = (state: RootState) => ({
  pageHeader: state.app.data.pageHeader,
  bodyText: state.app.data.pageBodyText?.plain || state.app.data.pageBodyText,
  boldText: state.app.data.pageBodyText?.boldText,
  advice: state.app.data.advice,
  bottomInfoText: state.app.data.bottomInfoText,
  feedbackHeading: state.app.data.feedbackHeading,
})

export const getIdentityVerificationConsentText = (state: RootState) =>
  state.app.data.identityVerificationConsentText

type ValueOption = { label: string; value: string }

export const getTimeSlotOptions = (state: RootState): ValueOption[] =>
  state.app.data.timeSlotOptions

export const getVacantOrOccupiedOptions = (state: RootState): ValueOption[] =>
  state.app.data.vacantOrOccupiedOptions

export const getLocationOfKeysOptions = (state: RootState): ValueOption[] =>
  state.app.data.locationOfKeysOptions

export const getStateOfIssueItems = (state: RootState): ValueOption[] =>
  state.app.data.stateOfIssueItems
export const getCountryOfIssueItems = (state: RootState): ValueOption[] =>
  state.app.data.countryOfIssueItems

export const getOptedInServices = (state: RootState) =>
  state.app.data.optedInServices as Service[]
export const getLifeSupportOptions = (state: RootState): ValueOption[] =>
  state.app.data.lifeSupportOptions
export const getMedicalCoolingOrHeatingOptions = (state: RootState): ValueOption[] =>
  state.app.data.medicalCoolingOrHeatingOptions

export const getConcessionCardForm = (state: RootState) => ({
  concessionTypeOptions: state.app.data.concessionCard?.concessionTypeOptions,
  authorisationText: state.app.data.concessionCard?.authorisationText,
  additionalAuthorizations:
    state.app.data.concessionCard?.additionalAuthorizations ||
    ([] as AdditionalConcessionAuthorization[]),
  iUnderstandText: state.app.data.concessionCard?.iUnderstandText,
})

export const getPageBg = (state: RootState) => state.app.pageBg
export const getFormIsSubmitting = (state: RootState) => state.app.formIsSubmitting

export const campaignType = (state: RootState) => state.app.campaignType
export const agentPhoneEicDetails = (state: RootState) =>
  fromNullable(state.app.agentPhoneEic)
