import * as t from 'io-ts'
import { optional } from './validate'
import { stepSchema, addressSchema } from './wizardStep'
import { addressFilter } from './siteAddress'

export const nmiFieldsSchema = t.type({
  nmi: optional(t.string),
  addressIsCorrect: optional(t.string),
  address: optional(addressSchema),
  addressFilter: optional(addressFilter)
})

export type NmiEntry = t.TypeOf<typeof nmiFieldsSchema>

export const nmiEntrySchema = stepSchema({
  nmiEntry: t.type({
    fields: nmiFieldsSchema,
  }),
})
