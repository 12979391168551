import { createAction } from '@reduxjs/toolkit'

export const remoteAssetRegistered = createAction<{
  key: string
  href: string
}>('REMOTE_ASSET_REGISTERED')

export const remoteAssetRequested = createAction<string>('REMOTE_ASSET_REQUESTED')
export const remoteAssetAlreadyCached = createAction<string>(
  'REMOTE_ASSET_ALREADY_CACHED'
)
export const remoteAssetLoaded = createAction<{ key: string; data: any }>(
  'REMOTE_ASSET_LOADED'
)
export const remoteAssetFailed = createAction<{ key: string; error: string }>(
  'REMOTE_ASSET_FAILED'
)
