import { Image } from '@cx/connect-ui/lib'
import { whitespace } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import React from 'react'
import AlintaLogo from '../assets/images/alinta-energy-logo-landscape.png'

type Props = {
  partnerLogoUrl: string
  partnerName: string
}

const verticalLineStyle = css`
  display: block;
  width: 1px;
  flex-basis: 1px;
  height: 40px;
  background: #ccc;

  position: relative;
  top: 5px;

  margin: 0 ${whitespace[3]};
`

const leftStyle = css`
  flex: 1;
  display: flex;

  justify-content: flex-end;
`

const rightStyle = css`
  flex: 1;

  display: flex;
  justify-content: flex-start;
`

const TwoLogoBanner = ({ partnerLogoUrl, partnerName }: Props) => {
  return (
    <>
      <div className={cx(leftStyle)}>
        <Image src={AlintaLogo} alt="Alinta Energy" width={140} height={48} />
      </div>
      <div className={cx(verticalLineStyle)} />
      <div className={cx(rightStyle)}>
        <Image src={partnerLogoUrl} alt={partnerName} width={120} height={48} />
      </div>
    </>
  )
}

export default TwoLogoBanner
