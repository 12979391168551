import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import sagas from './sagas'
import { RootState } from './state'
import { appWindow } from './window'

export type ReduxDevtoolsWindow = {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R
}

type HotModule = {
  hot?: any
}

const hotModule: HotModule = module as any

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers =
  appWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(initialState?: RootState) {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, thunk))
  )

  let task = sagaMiddleware.run(sagas)

  if (hotModule.hot) {
    // Enable Webpack hot module replacement for reducers
    hotModule.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default
      store.replaceReducer(nextReducer)
    })

    hotModule.hot.accept('./sagas', () => {
      const nextSaga = require('./sagas').default
      task.cancel()
      task = sagaMiddleware.run(nextSaga)
    })
  }

  return store
}

export default configureStore()
