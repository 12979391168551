import { RootState } from '../../state'
import { fromNullable } from 'fp-ts/lib/Option'

export function bundleInitialState(state: RootState) {
  return state.wizardSteps.bundle.initialState
}

export function siteAddressInitialState(state: RootState) {
  return state.wizardSteps.siteAddress.initialState
}

export function siteAddressSmeInitialState(state: RootState) {
  return state.wizardSteps.siteAddressSme.initialState
}

export function nmiEntryInitialState(state: RootState) {
  return state.wizardSteps.nmiEntry.initialState
}

export function customerDetailsInitialState(state: RootState) {
  return state.wizardSteps.customerDetails.initialState
}

export function businessDetailsInitialState(state: RootState) {
  return state.wizardSteps.businessDetails.initialState
}

export function visualSiteInspectionInitialState(state: RootState) {
  return state.wizardSteps.visualSiteInspection.initialState
}

export function customerIdentityInitialState(state: RootState) {
  return state.wizardSteps.customerIdentity.initialState
}

export function concessionsInitialState(state: RootState) {
  return state.wizardSteps.concessions.initialState
}

export function vicSafetyQuestionsInitialState(state: RootState) {
  return state.wizardSteps.vicSafetyQuestions.initialState
}

export function nswSafetyQuestionsInitialState(state: RootState) {
  return state.wizardSteps.nswSafetyQuestions.initialState
}

export function postalDeliveryPreferenceInitialState(state: RootState) {
  return state.wizardSteps.postalDeliveryPreference.initialState
}

export function postalDeliveryPreferenceSiteAddress(state: RootState) {
  return fromNullable(state.wizardSteps.postalDeliveryPreference.siteAddress)
}

export function consentInitialState(state: RootState) {
  return state.wizardSteps.consent.initialState
}

export function businessDetailsLookup(state: RootState) {
  return state.abr
}

export function mobileNumberVerification(state: RootState) {
  return state.mobileNumberVerification
}
