import * as t from 'io-ts'
import { optional } from './validate'
import { stepSchema } from './wizardStep'

export const vicSafetyQuestionsFormSchema = t.type({
  powerCurrentlyOn: optional(t.boolean),
  renovationsPlannedOrInProgress: optional(t.boolean),
  recentlyDeEnergised: optional(t.boolean),
})

export type VicSafetyQuestions = t.TypeOf<typeof vicSafetyQuestionsFormSchema>

export const vicSafetyQuestionsSchema = stepSchema({
  safetyQuestions: optional(vicSafetyQuestionsFormSchema),
  showFollowUpQuestions: t.boolean,
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
})

export const nswSafetyQuestionsFormSchema = t.type({
  renovationsPlannedOrInProgress: optional(t.boolean),
  previousRenovationsOrAlterations: optional(t.boolean),
  customerOrRepresentativeCanEnsureMainSwitchIsOff: optional(t.boolean),
  powerOffForLongerThanSixMonths: optional(t.boolean),
})

export type NswSafetyQuestions = t.TypeOf<typeof nswSafetyQuestionsFormSchema>

export const nswSafetyQuestionsSchema = stepSchema({
  safetyQuestions: optional(nswSafetyQuestionsFormSchema),
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
})
