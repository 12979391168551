import React from 'react'
import classNames from 'classnames'
import './ChevronRightIcon.scss'

type Props = {
  className?: string
}

export default ({ className }: Props) => {
  return (
    <svg
      className={classNames(className, 'aumo_icon', 'aumo_icon-chevron-right')}
      viewBox="0 0 9 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeWidth="1"
        fillRule="evenodd"
        transform="translate(-43.000000, -538.000000)"
        strokeLinecap="square"
      >
        <g transform="translate(25.000000, 520.000000)" strokeWidth="2">
          <g transform="translate(22.181818, 25.000000) scale(-1, 1) rotate(90.000000) translate(-22.181818, -25.000000) translate(16.000000, 21.000000)">
            <path d="M0.705882353,1.45454545 L6,6.90909091" />
            <path
              d="M5.64705882,1.45454545 L10.9411765,6.90909091"
              transform="translate(8.470588, 4.000000) scale(-1, 1) translate(-8.470588, -4.000000) "
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
