import { createAction } from '@reduxjs/toolkit'
import { FormValidationErrors } from './reducer'

export const apiContractFailureEncountered = createAction<Error>(
  'API_CONTRACT_FAILURE_ENCOUNTERED'
)

export const apiContractFailureCleared = createAction('API_CONTRACT_FAILURE_CLEARED')

export const formSubmissionFailureEncountered = createAction<{
  errorCode: string
  message: string
}>('FORM_SUBMISSION_FAILURE_ENCOUNTERED')

export const formSubmissionFailureCleared = createAction(
  'FORM_SUBMISSION_FAILURE_CLEARED'
)

export const formValidationUpdated = createAction<FormValidationErrors>(
  'FORM_VALIDATION_UPDATED'
)
export const formValidationCleared = createAction('FORM_VALIDATION_CLEARED')
export const formFieldValidationUpdated = createAction<{
  fieldPath: string
  message: string
}>('FORM_FIELD_VALIDATION_UPDATED')
export const formFieldValidationCleared = createAction<string>(
  'FORM_FIELD_VALIDATION_CLEARED'
)
