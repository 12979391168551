import { AddressValue } from '@cx/connect-ui/lib/components/fields/address'
import { createReducer } from '@reduxjs/toolkit'
import {
  bundleInitialStateReceived,
  concessionsInitialStateReceived,
  consentInitialStateReceived,
  customerDetailsInitialStateReceived,
  customerIdentityInitialStateReceived,
  postalDeliveryPreferenceInitialStateReceived,
  visualSiteInspectionInitialStateReceived,
  customerBusinessDetailsInitialStateReceived,
  siteAddressInitialStateReceieved,
  nmiEntryInitialStateReceived,
  businessDetailsInitialStateReceived,
  abrBusinessDetailsPopulated,
  vicSafetyQuestionsInitialStateReceived,
  siteAddressSmeInitialStateReceived,
  nswSafetyQuestionsInitialStateReceived,
} from './actions'
import {
  bundle,
  concessions,
  consent,
  customerDetailsForConfirmation,
  businessDetailsForConfirmation,
  customerIdentity,
  postalDeliveryPreference,
  visualSiteInspection,
  siteAddress,
  nmiEntry,
  vicSafetyQuestions,
  siteAddressSme,
  nswSafetyQuestions,
} from './initialStates'

const initialState = {
  bundle: {
    initialState: { ...bundle },
  },
  siteAddressSme: { initialState: { ...siteAddressSme } },
  siteAddress: { initialState: { ...siteAddress } },
  nmiEntry: { initialState: { ...nmiEntry } },
  customerDetails: {
    initialState: { ...customerDetailsForConfirmation },
  },
  businessDetails: {
    initialState: { ...businessDetailsForConfirmation },
  },
  visualSiteInspection: {
    initialState: { ...visualSiteInspection },
  },
  customerIdentity: {
    initialState: { ...customerIdentity },
  },
  concessions: {
    initialState: { ...concessions },
  },
  vicSafetyQuestions: {
    initialState: { ...vicSafetyQuestions },
  },
  nswSafetyQuestions: {
    initialState: { ...nswSafetyQuestions },
  },
  postalDeliveryPreference: {
    initialState: { ...postalDeliveryPreference },
    siteAddress: undefined as AddressValue | undefined,
  },
  consent: {
    initialState: { ...consent },
  },
}

// I know, the name, but what would you call the state subtree that stores initial form states?
export type WizardStepsState = typeof initialState

export default createReducer<WizardStepsState>(initialState, (b) => {
  b.addCase(bundleInitialStateReceived, (s, { payload: initialState }) => {
    s.bundle = initialState
  })

  b.addCase(siteAddressSmeInitialStateReceived, (s, { payload: initialState }) => {
    s.siteAddressSme = initialState
  })

  b.addCase(siteAddressInitialStateReceieved, (s, { payload: initialState }) => {
    s.siteAddress = initialState
  })

  b.addCase(nmiEntryInitialStateReceived, (s, { payload: initialState }) => {
    s.nmiEntry = initialState
  })

  b.addCase(customerDetailsInitialStateReceived, (s, { payload: initialState }) => {
    s.customerDetails = initialState

    if (s.customerDetails.initialState.businessDetails) {
      const trusteeDetails = s.customerDetails.initialState.businessDetails.trusteeDetails

      s.customerDetails.initialState.businessDetails = {
        businessEntity: s.customerDetails.initialState.businessDetails.businessEntity,
        trusteeDetails: trusteeDetails?.trusteeCategory ? trusteeDetails : undefined,
      }
    }
  })

  b.addCase(businessDetailsInitialStateReceived, (s, { payload: initialState }) => {
    s.businessDetails = initialState
  })

  b.addCase(abrBusinessDetailsPopulated, (s, { payload }) => {
    if (s.businessDetails.initialState.businessDetails) {
      const trusteeDetails = s.businessDetails.initialState.businessDetails.trusteeDetails

      s.businessDetails.initialState.businessDetails = {
        businessEntity: payload,
        trusteeDetails: trusteeDetails?.trusteeCategory
          ? trusteeDetails
          : payload.isTrustEntity
          ? customerDetailsForConfirmation.businessDetails?.trusteeDetails
          : undefined,
      }
    }
  })

  b.addCase(customerBusinessDetailsInitialStateReceived, (s, { payload }) => {
    s.customerDetails = {
      initialState: { ...s.customerDetails.initialState, customerType: 'business' },
    }

    if (s.customerDetails.initialState.businessDetails) {
      const trusteeDetails = s.customerDetails.initialState.businessDetails.trusteeDetails

      s.customerDetails.initialState.businessDetails = {
        businessEntity: payload,
        trusteeDetails: trusteeDetails?.trusteeCategory
          ? trusteeDetails
          : payload.isTrustEntity
          ? customerDetailsForConfirmation.businessDetails?.trusteeDetails
          : undefined,
      }
    }
  })

  b.addCase(visualSiteInspectionInitialStateReceived, (s, { payload: initialState }) => {
    s.visualSiteInspection = initialState
  })

  b.addCase(customerIdentityInitialStateReceived, (s, { payload: initialState }) => {
    s.customerIdentity = initialState
  })

  b.addCase(concessionsInitialStateReceived, (s, { payload: initialState }) => {
    s.concessions = initialState
  })

  b.addCase(vicSafetyQuestionsInitialStateReceived, (s, { payload: initialState }) => {
    s.vicSafetyQuestions = initialState
  })

  b.addCase(nswSafetyQuestionsInitialStateReceived, (s, { payload: initialState }) => {
    s.nswSafetyQuestions = initialState
  })

  b.addCase(
    postalDeliveryPreferenceInitialStateReceived,
    (s, { payload: initialState }) => {
      s.postalDeliveryPreference = {
        ...s.postalDeliveryPreference,
        initialState: initialState.initialState,
      }
    }
  )

  b.addCase(consentInitialStateReceived, (s, { payload: initialState }) => {
    s.consent = initialState
  })
})
