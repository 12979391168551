import { RootState } from '../../../state'
import {
  Deal,
  ProductAdvice,
  PreConsentStatement,
  Hypermedia,
  AdditionalInfoForService,
  MandatoryService,
} from '@cx/connect-ui/lib/components/energy/types'
import { fromNullable } from 'fp-ts/lib/Option'
import _ from '../../../lodashFp'

type DynamicHeadline = {
  dualHeadline: string
  elecHeadline: string
  gasHeadline: string
}

type HeadlineText = {
  creditText?: string
  genericText?: string
  type: string
}

function isHeadlineText(headline: any): headline is HeadlineText {
  return headline.type !== undefined && 
  (headline.type === "genericText" || headline.type === "creditText") 
}

function isDynamicHeadline(headline: any ): headline is DynamicHeadline {
  return headline.dualHeadline !== undefined;
}

const getHeadline = 
(headline: unknown, requestedElectricity: boolean, requestedGas: boolean) =>{

  if (isHeadlineText(headline)) {
    if (headline.creditText && isDynamicHeadline(headline.creditText)){
    let creditText  = headline.creditText
    return (requestedElectricity&&requestedGas? creditText.dualHeadline: 
            requestedGas? headline.creditText.gasHeadline: 
            headline.creditText.elecHeadline)
    }
    else 
      return  headline.genericText 
  }
  else
    return headline;
}

export const getOffer = (x: RootState) => ({
  pageHeading: getHeadline(x.app.data.bestDealHeading,
                           x.customer.requestedElectricity,
                           x.customer.requestedGas,) as string,
  deal: x.app.data.bestDeal as Deal,
  mandatoryServices: x.app.data.mandatoryServices as MandatoryService[],
  additionalInfo: fromNullable(x.app.data.additionalInfo as AdditionalInfoForService[]),
  productAdvice: fromNullable(x.app.data.productAdvice as ProductAdvice[]),
  meterDetailsLookupSucceeded: x.app.data.meterDetailsLookupSucceeded as boolean,
  preConsentStatements: fromNullable(
    x.app.data.preConsentStatements as PreConsentStatement[]
  ),
  links: x.app.data._links as { [id: string]: Hypermedia },
})

export const getAcceptHypermedia = (x: RootState) => ({
  elecAction: fromNullable(_.get('app.data.elecAction')(x) as Hypermedia),
  gasAction: fromNullable(_.get('app.data.gasAction')(x) as Hypermedia),
  bundleAction: fromNullable(_.get('app.data.bundleAction')(x) as Hypermedia),
})

export const getOfferQuoteHypermedia = (x: RootState) =>
  fromNullable(_.get('app.data.offerQuoteAction')(x) as Hypermedia)
