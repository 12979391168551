import { Image } from '@cx/connect-ui/lib'
import React from 'react'

type Props = {
  partnerLogoUrl: string
  partnerName: string
}

export const LockupLogoBanner = ({ partnerLogoUrl, partnerName }: Props) => {
  return (
    <>
      <Image src={partnerLogoUrl} alt={partnerName} width={120} height={80} />
    </>
  )
}

export default LockupLogoBanner
