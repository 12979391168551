import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { registerAppRootForModal } from '@cx/connect-ui'
import App from './App'
import store from './configureStore'
import appInsights from './integrations/appInsights'

// These are here to fix the code splitting ordering warnings https://github.com/facebook/create-react-app/issues/5372
// eslint-disable-next-line
import TextInput from './wizard/components/fields/TextInput'
// eslint-disable-next-line
import RadioInputGroup from './wizard/components/fields/RadioInputGroup'

const appRootId = 'root'
registerAppRootForModal(appRootId)

// Could move this to a saga?
appInsights.initialize()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById(appRootId)
)
