import * as t from 'io-ts'
import { moveInAvailability, moveInPicker } from './confirmDetails'
import { optional } from './validate'
import { stepSchema } from './wizardStep'

export const addressSchema = t.type({
  unit: optional(t.string),
  number: t.string,
  street: t.string,
  suburb: t.string,
  postcode: t.string,
  state: t.string,
  country: t.string,
})

export const addressFilter = t.type({
  postcode: optional(t.string),
  state: optional(t.string)
})

const saleType = t.union([t.literal('MoveIn'), t.literal('Transfer')])

export const addressFieldsSchema = t.type({
  address: optional(addressSchema),
  addressFilter: optional(addressFilter),
  saleType: optional(saleType),
  connectionDate: optional(t.string),
  currentAddress: optional(addressSchema),
})

export type SiteAddress = t.TypeOf<typeof addressFieldsSchema>

export const siteAddressSchema = stepSchema({
  siteAddress: t.type({
    label: t.string,
    fields: addressFieldsSchema,
    saleTypeLabel: optional(t.string),
    saleTypeTexts: optional(t.record(saleType, t.string)),
  }),
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
  moveInAvailability: optional(moveInAvailability),
  moveInPicker: optional(moveInPicker),
})

export const addressSmeFieldsSchema = t.type({
  address: optional(addressSchema),
  addressFilter: optional(addressFilter)
})

export type SiteAddressSme = t.TypeOf<typeof addressSmeFieldsSchema>

export const siteAddressSmeSchema = t.type({
  siteAddress: t.type({
    label: t.string,
    fields: addressSmeFieldsSchema,
  }),
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
  _actions: t.UnknownRecord,
  _assets: t.UnknownRecord,
  _links: t.UnknownRecord,
})
