import * as t from 'io-ts'

export const notFoundSchema = t.type({
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
  _assets: t.UnknownRecord,
  _links: t.UnknownRecord,
})

export type NotFound = t.TypeOf<typeof notFoundSchema>
