import { RootState } from '../state'
import { Option, some, none, map } from 'fp-ts/lib/Option'
import { Address } from '../wizard/components/fields/formik/address'

type SupportDetails = {
  name: string
  email: string
  phone: string
  company: string
  imageUrl: string
  displayUrl: string
}

export const assetExists = (x: RootState, key: string) =>
  !!x.remoteAssets.remoteAssets[key]
export const getAsset = (x: RootState, key: string) => x.remoteAssets.remoteAssets[key]

export const isAssetLoading = (x: RootState, key: string) =>
  assetExists(x, key) ? getAsset(x, key).state === 'LOADING' : false
export const isAssetLoaded = (x: RootState, key: string) =>
  assetExists(x, key) ? getAsset(x, key).state === 'READY' : false
export const didAssetFail = (x: RootState, key: string) =>
  assetExists(x, key) ? getAsset(x, key).state === 'FAILED' : false

const getAsOption = (x: RootState, key: string) =>
  isAssetLoaded(x, key) ? some(getAsset(x, key).data) : none

export const getSupportDetails = (x: RootState): Option<SupportDetails> =>
  getAsOption(x, 'supportDetails')

export const getPropertyAddress = (x: RootState): Option<Address> =>
  getAsOption(x, 'propertyAddress')

export const getPostcode = (x: RootState): Option<string> =>
  map((p: { postcode: string }) => p.postcode)(getAsOption(x, 'postcode'))
