import { PageName } from 'wizard/steps/pages'
import _lowerCase from 'lodash/fp/lowerCase'
import _capitalize from 'lodash/fp/capitalize'
import _pipe from 'lodash/fp/pipe'

function getPageTitle(name: PageName) {
  switch (name) {
    case 'best-deal':
      return 'Plan details'
    case 'concessions':
      return 'Special requirements'
    case 'confirm-details':
      return 'Account holder'
    case 'confirm-identity':
      return 'Identification'
    case 'site-address':
      return 'Your address'
    case 'consent':
      return 'Final step'

    default:
      return _pipe(_lowerCase, _capitalize)(name)
  }
}

export default getPageTitle
