import React from 'react'
import _ from 'lodash'
import { css, cx } from 'emotion'
import { motion } from 'framer-motion'

const sparkStyle = css`
  position: absolute;
  pointer-events: none;
  font-size: 30px;
`

type Props = {
  trigger?: boolean
  className?: string
}

const config = {
  randomSpread: 120,
  degrees: 360,
  scaleFactor: 2,
}

const Sparks = ({ trigger, className }: Props) => {
  const count = 5
  const stepsArray = _.fill(Array(count), undefined).map(_ => ({
    x: Math.random() * config.randomSpread - config.randomSpread / 2,
    y: Math.random() * config.randomSpread - config.randomSpread / 2,
    rotate: Math.random() * config.degrees,
    scale: Math.random() * config.scaleFactor + 1,
  }))

  if (!trigger) {
    return <></>
  }

  return (
    <div className={className}>
      {stepsArray.map((v, i) => (
        <motion.div
          className={cx(sparkStyle)}
          key={i}
          initial={{
            x: 0,
            y: 0,
            scale: 1,
            rotateZ: v.rotate,
          }}
          animate={{
            x: v.x,
            y: v.y,
            opacity: 0,
            scale: v.scale,
          }}
          transition={{
            type: 'tween',
            ease: 'easeOut',
          }}
        >
          ·
        </motion.div>
      ))}
    </div>
  )
}

export default Sparks
