export const INVITATION = 'invitation'
export const FIND_BEST_DEAL = 'find-best-deal'
export const FIND_DEAL = 'find-deal'
export const BEST_DEAL = 'best-deal'
export const FULL_DEAL_DETAILS = 'full-deal-details'
export const TERMS_AND_CONDITIONS = 'terms-and-conditions'
export const SITE_ADDRESS_SME = 'site-address-sme'
export const SITE_ADDRESS = 'site-address'
export const NMI_ENTRY = 'nmi-entry'
export const BUSINESS_DETAILS = 'business-details'
export const CONFIRM_DETAILS = 'confirm-details'
export const VISUAL_SITE_INSPECTION = 'visual-site-inspection'
export const CONFIRM_IDENTITY = 'confirm-identity'
export const CONCESSIONS = 'concessions'
export const VIC_SAFETY_QUESTIONS = 'safety-questions'
export const NSW_SAFETY_QUESTIONS = 'nsw-safety-questions'
export const MEDICAL_NEEDS = 'medical-needs'
export const CONSENT = 'consent'
export const POSTAL_ADDRESS = 'postal-address'
export const FEEDBACK = 'feedback'
export const THANKS = 'thanks'
export const INVITATION_EXPIRED = 'invitation-expired'
export const INVITATION_COMPLETED = 'invitation-completed'
export const TRY_AGAIN_LATER = 'try-again-later'
export const NOT_FOUND = 'not-found'
export const INVITATION_WITHDRAWN = 'invitation-withdrawn'
export const ALINTA_STOP_SALE = 'site-maintenance'

export type PageName =
  | typeof INVITATION
  | typeof FIND_BEST_DEAL
  | typeof FIND_DEAL
  | typeof BEST_DEAL
  | typeof FULL_DEAL_DETAILS
  | typeof TERMS_AND_CONDITIONS
  | typeof SITE_ADDRESS_SME
  | typeof SITE_ADDRESS
  | typeof NMI_ENTRY
  | typeof CONFIRM_DETAILS
  | typeof BUSINESS_DETAILS
  | typeof VISUAL_SITE_INSPECTION
  | typeof CONFIRM_IDENTITY
  | typeof CONCESSIONS
  | typeof VIC_SAFETY_QUESTIONS
  | typeof NSW_SAFETY_QUESTIONS
  | typeof MEDICAL_NEEDS
  | typeof CONSENT
  | typeof POSTAL_ADDRESS
  | typeof FEEDBACK
  | typeof THANKS
  | typeof TRY_AGAIN_LATER
  | typeof NOT_FOUND
  | typeof INVITATION_EXPIRED
  | typeof INVITATION_COMPLETED
  | typeof INVITATION_WITHDRAWN
  | typeof ALINTA_STOP_SALE
