import { ReduxDevtoolsWindow } from './configureStore'
import { FeatureToggles } from './lib/featureToggles'

export type AppWindow = Window &
  typeof globalThis &
  FeatureToggles &
  ReduxDevtoolsWindow & {
    handleLinkInPayloadClick: (e: any) => void
  }

export const appWindow = window as AppWindow
