import * as t from 'io-ts'
import { optional } from 'wizard/api/contracts/validate'

export const expiredSchema = t.type({
  expiryMessage: t.string,
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
  _actions: t.UnknownRecord,
  _assets: t.UnknownRecord,
  _links: optional(t.UnknownRecord),
})

export type Expired = t.TypeOf<typeof expiredSchema>
