import { formFieldValidationCleared, formFieldValidationUpdated } from 'errors/actions'
import { chain, Either, fold, map } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import produce from 'immer'
import { UncheckedResponse } from 'lib/api'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { lookupAbnOrAcn } from 'wizard/api/client/abrLookup'
import { validateSchema } from 'wizard/api/contracts/validate'
import {
  abrLookupFailed,
  abrLookupInitiated,
  activeBusinessAbrResponseReceived,
  inactiveBusinessAbrResponseReceived,
  noAbrResultsResponseReceived,
} from 'wizard/state/abrLookup'
import { abrBusinessDetailsPopulated, customerBusinessDetailsInitialStateReceived } from 'wizard/state/actions'
import {
  abrLookupSchema,
  BusinessDetails,
  BusinessLookupResponse,
} from '../api/contracts/abrLookup'

type LookupError = 'INACTIVE_ABN' | 'NO_RESULTS' | 'LOOKUP_FAILED'
type LookupStatus = LookupError | 'SUCCESS'

const lookupStatus = (success: boolean, abnStatus?: string): LookupStatus => {
  if (!success || !abnStatus) return 'NO_RESULTS'
  if (abnStatus && abnStatus.toLowerCase() !== 'active') return 'INACTIVE_ABN'
  return 'SUCCESS'
}

function* handleAbrLookup() {
  yield takeLatest(abrLookupInitiated.type, function* ({
    payload: abn,
  }: ReturnType<typeof abrLookupInitiated>) {
    let res: Either<Error, UncheckedResponse> = yield call(lookupAbnOrAcn, abn)

    const validateResponse = (res: Either<Error, UncheckedResponse>) =>
      validateSchema(abrLookupSchema, `ABR_LOOKUP`, res, true)

    const addEntityNameToTradingNames = (payload: BusinessLookupResponse) =>
      produce(payload, (draft) => {
        if (draft.entityLookup) {
          draft.entityLookup.tradingNames = draft.entityLookup.tradingNames.concat([
            draft.entityLookup.entityName,
          ])
        }
      })

      const enrichWithStatus = (payload: BusinessLookupResponse) => ({
        payload: payload.entityLookup,
        status: lookupStatus(payload.success, payload.entityLookup?.abnStatus),
      })

    const errorToAction = (e: Error) => abrLookupFailed(e.message)

    type PayloadStatus = { payload?: BusinessDetails; status: LookupStatus }
    const payloadToAction = ({ payload, status }: PayloadStatus) => {
      if (!payload || status === 'NO_RESULTS') return noAbrResultsResponseReceived()

      switch (status) {
        case 'INACTIVE_ABN':
          return inactiveBusinessAbrResponseReceived(payload)
        case 'LOOKUP_FAILED':
          return abrLookupFailed('Could not contact ABR, please try again later.')
        case 'SUCCESS':
          return activeBusinessAbrResponseReceived(payload)
      }
    }

    const action = pipe(
      res,
      chain(validateResponse),
      map(addEntityNameToTradingNames),
      map(enrichWithStatus),
      fold(errorToAction, payloadToAction)
    )

    yield put(formFieldValidationCleared('businessDetails.abn'))

    yield put(action)
  })
}

function* handleInactiveAbrResponse() {
  yield takeLatest(inactiveBusinessAbrResponseReceived.type, function* ({
    payload: lookup,
  }: ReturnType<typeof inactiveBusinessAbrResponseReceived>) {
    yield put(
      formFieldValidationUpdated({
        fieldPath: 'businessDetails.abn',
        message: `Oops! this ABN / ACN has been marked as ${lookup.abnStatus}`,
      })
    )
  })
}

function* handleNoAbrResults() {
  yield takeLatest(noAbrResultsResponseReceived.type, function* () {
    yield put(
      formFieldValidationUpdated({
        fieldPath: 'businessDetails.abn',
        message: `Could not find any results for this ABN / ACN, please check for any typos or errors`,
      })
    )
  })
}

function* handleActiveAbrResponse() {
  yield takeLatest(activeBusinessAbrResponseReceived.type, function* ({
    payload: lookup,
  }: ReturnType<typeof inactiveBusinessAbrResponseReceived>) {
    yield put(
      customerBusinessDetailsInitialStateReceived({
        ...lookup,
        tradingName: lookup.tradingNames[0],
      }))
    yield put(
        abrBusinessDetailsPopulated({
          ...lookup,
          tradingName: lookup.tradingNames[0],
      }))
  })
}

export default function* root() {
  yield all([
    fork(handleAbrLookup),
    fork(handleInactiveAbrResponse),
    fork(handleActiveAbrResponse),
    fork(handleNoAbrResults),
  ])
}
