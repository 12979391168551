import { Option, fold } from 'fp-ts/lib/Option'
import { getOrElse } from 'fp-ts/lib/Option'

export const whenSome = <T extends any, R>(val: Option<T>, map: (t: T) => R) =>
  fold(
    () => undefined,
    (t: T) => map(t)
  )(val)

export const match = <T extends any, R>(
  val: Option<T>,
  onSome: (t: T) => R,
  onNone: () => R
) =>
  fold(
    () => onNone(),
    (t: T) => onSome(t)
  )(val)

export const defaultValue = <T extends any>(opt: Option<T>, val: T) =>
  getOrElse(() => val)(opt)

export const defaultWith = <T>(val: T) => (opt: Option<T>) => getOrElse(() => val)(opt)
