import { offerQuoteSent, submitOfferQuoteButtonClicked } from 'customer/actions'
import { OfferQuoteSentTo } from 'customer/reducers'
import { isNone } from 'fp-ts/lib/Option'
import handleError from 'lib/handleError'
import { defaultValue } from 'lib/option'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { getOfferQuoteHypermedia } from 'wizard/steps/bestDeal/selectors'
import { submitForm } from './formSubmissionSagas'

function* handleQuoteRequest() {
  yield takeLatest(
    submitOfferQuoteButtonClicked.type,
    function* ({
      payload: { email, mobile },
    }: ReturnType<typeof submitOfferQuoteButtonClicked>) {
      try {
        const quoteAction = yield select(getOfferQuoteHypermedia)
        if (isNone(quoteAction)) {
          console.error('Email Quote hypermedia is missing, cannot send quote')
        } else {
          const action = defaultValue(quoteAction, {} as any)

          const res = yield call(submitForm, {
            ...action,
            body: { emailAddress: email, mobile: mobile.replaceAll(' ', '') },
          })

          const sentTo: OfferQuoteSentTo[] = []
          if (email) sentTo.push('Email')
          if (mobile) sentTo.push('Mobile')
          yield put(offerQuoteSent(sentTo))
          return res
        }
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

export default function* root() {
  yield all([fork(handleQuoteRequest)])
}
