import {
  Columns,
  InlineSvg,
  Panel,
  PrimaryButton,
  Spacer,
  Stack,
  Text,
  ThemeDefinition,
  Title,
  useTheme,
} from '@cx/connect-ui'
import { Column } from '@cx/connect-ui/lib/components/layout/Columns'
import { breakpoints } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import React from 'react'
import BillImage from './alinta-bill.png'
import InfoIcon from './icon-info-blue.svg'

const modalStyle = (theme: ThemeDefinition) => css`
  text-align: center;
  color: ${theme.baseFontColor};

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 460px;
    max-width: 460px;
  }
`

type Props = {
  onMoreHelp: () => void
  onOkay: () => void
}

export const NmiInstructionsModal = ({ onMoreHelp, onOkay }: Props) => {
  const theme = useTheme()

  return (
    <div className={cx(modalStyle(theme))}>
      <Stack>
        <Spacer height={16} />
        <Panel
          color="secondary"
          icon={
            <InlineSvg src={InfoIcon} alt="Information icon (the letter I in a circle)" />
          }
          hasDescender
        >
          <Stack spacing="generous" align="center">
            <Title>How to find your NMI</Title>
            <Text>
              It might seem confusing, but your NMI isn't actually too hard to locate.
            </Text>
            <Text>
              Grab a recent eletricity bill and look at the top of each page, near the
              other details like your supply address and billing period.
            </Text>
            <Text bold>
              In most states, NMIs are an 11 digit number - e.g. 44012345678.
            </Text>
            <Text bold>
              Queensland, however, often has two letters followed by nine digits - e.g.
              QB044123456.
            </Text>
          </Stack>
        </Panel>
        <Spacer height={16} />
        <Text>Here's an example of where to look:</Text>
        <img
          src={BillImage}
          alt="Where to find your NMI on your printed Electricity bill"
        />
        <Spacer height={16} />
        <Columns>
          <Column horizontalAlignment="stretch">
            <PrimaryButton onClick={onMoreHelp}>I need more help</PrimaryButton>
          </Column>
          <Column horizontalAlignment="stretch">
            <PrimaryButton onClick={onOkay}>Okay</PrimaryButton>
          </Column>
        </Columns>
      </Stack>
    </div>
  )
}
