import cx from 'classnames'
import React, { ReactNode } from 'react'
import './BodyText.scss'
import { ThemeDefinition, useTheme } from '@cx/connect-ui/lib/lib/theme'
import { css } from 'emotion'
import { useLightBackground } from 'theme'

type Props = {
  className?: string
  children?: ReactNode
  dangerouslySetInnerHTML?: {
    __html: string
  }
  bold?: boolean
}

const style = (theme: ThemeDefinition, lightBg: boolean) => css`
  font-family: ${theme.bodyFont};
  color: ${lightBg ? theme.baseFontColor : 'white'};
`

const BodyText = ({ bold, className, children, dangerouslySetInnerHTML }: Props) => {
  const theme = useTheme()
  const lightBg = useLightBackground()

  const classes = cx(
    className,
    'aumo_body-text',
    {
      'aumo_body-text--bold': bold,
    },
    style(theme, lightBg)
  )

  return (
    <div className={classes} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </div>
  )
}

export default BodyText
