import * as t from 'io-ts'
import { optional } from './validate'
import { stepSchema, legalNameSchema } from './wizardStep'

const identityDocumentTypeSchema = t.union([
  t.literal('driverLicence'),
  t.literal('passport'),
  t.literal('medicareCard'),
])

const identityDocumentSchema = t.type({
  type: t.union([
    t.literal('driverLicence'),
    t.literal('passport'),
    t.literal('medicareCard'),
  ]),
  driverLicence: optional(
    t.type({
      stateOfIssue: t.string,
      licenceNumber: t.string,
      expiryDate: t.string,
      cardNumber: optional(t.string),
    })
  ),
  passport: optional(
    t.type({
      passportNumber: t.string,
      countryOfIssue: t.string,
      expiryDate: t.string,
    })
  ),
  medicareCard: optional(
    t.type({
      medicareNumber: t.string,
      medicareIrn: t.string,
      medicareCardColour: t.string,
      expiryDate: t.string,
    })
  ),
})

export const identitySchema = t.type({
  legalName: legalNameSchema,
  dateOfBirth: optional(t.string),
  identityDocument: optional(identityDocumentSchema),
  consentGivenForIdentityVerification: optional(t.boolean),
})

export type CustomerIdentity = t.TypeOf<typeof identitySchema>
export type IdentityDocumentType = t.TypeOf<typeof identityDocumentTypeSchema>
export type IdentityDocument = t.TypeOf<typeof identityDocumentSchema>

export const confirmIdentitySchema = stepSchema({
  countryOfIssueItems: t.array(t.type({ label: t.string, value: t.string })),
  stateOfIssueItems: t.array(t.type({ label: t.string, value: t.string })),
  lockedNameFields: t.boolean,
  identity: t.type({
    legalText: t.string,
    identityVerificationConsentText: optional(t.string),
    fields: identitySchema,
  }),
  text: t.type({
    primary: t.string,
    additional: t.string,
    lockedNameMessage: t.string,
  }),
})
