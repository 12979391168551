import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { backButtonClicked } from '../wizard/actions'

export const useBackButton = () => {
  const dispatch = useDispatch()
  return useCallback((href: string) => dispatch(backButtonClicked(href)), [
    dispatch,
  ])
}
