import { PayloadAction } from '@reduxjs/toolkit'
import { Either, isRight } from 'fp-ts/lib/Either'
import { Option, some, none } from 'fp-ts/lib/Option'
import { fetchR } from 'lib/api'
import { parse, ParsedUrlQuery } from 'querystring'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import history from '../lib/history'
import { removeQueryString } from '../lib/queryString'
import {
  metadataLoaded,
  MetadataPayload,
  setActiveTheme,
  setCoBrandingPartner,
  setCallCentreNumber,
} from './actions'
import { getApplicationId } from 'lib/application'
import { defaultValue } from 'lib/option'
import { Theme } from '@cx/connect-ui/lib/lib/theme'
import { campaignTypeLoaded, phoneAgentDetailsLoaded } from 'wizard/actions'
import { customerOptInUpdated } from 'customer/actions'
import { BEST_DEAL, PageName } from 'wizard/steps/pages'

const QUERY_STRING_PARAM = 'SuperSecretThemeOverride'

function* watchThemeQueryString() {
  yield takeLatest('ENTERED_PAGE', function* () {
    const searchPath = window.location.search.replace('?', '')
    const params = yield call(parse, searchPath)

    if (params[QUERY_STRING_PARAM]) {
      const userTheme = params[QUERY_STRING_PARAM]
      yield put(setActiveTheme(userTheme))

      const updatedQueryParams = yield call(
        removeQueryString,
        searchPath,
        QUERY_STRING_PARAM
      )
      const newUrl = `${window.location.pathname}?${updatedQueryParams}`
      yield call(() => history.replace(newUrl))
    }
  })
}

// The full details page can send us back to the offer page without redux context
// we need to read the selected services from the URL and push them into the store
function* watchServicesQueryString() {
  yield takeLatest(
    'ENTERED_PAGE',
    function* ({ payload: pageName }: PayloadAction<PageName>) {
      const searchPath = window.location.search.replace('?', '')
      const params: ParsedUrlQuery = yield call(parse, searchPath)

      // Only applies on the best deal page
      if (pageName === BEST_DEAL && params['services']) {
        const queryValue: string | string[] = params['services']
        const services = Array.isArray(queryValue) ? queryValue : [queryValue]

        if (services.length > 0) {
          const gas = services.some((s) => s === 'Gas')
          const electricity = services.some((s) => s === 'Electricity')
          yield put(customerOptInUpdated({ electricity, gas }))
        }

        const updatedQueryParams = yield call(removeQueryString, searchPath, 'services')

        const newUrl = `${window.location.pathname}?${updatedQueryParams}`
        yield call(() => history.replace(newUrl))
      }
    }
  )
}

function* watchEnterPage() {
  yield takeLatest('ENTERED_PAGE', function* () {
    // get application Id
    const applicationId = defaultValue(getApplicationId(), null)
    if (applicationId == null) {
      console.warn('failed to determine application ID')
      return
    }

    // fetch metadata
    const doFetch = (applicationId: string) =>
      fetchR<MetadataPayload>({ href: `/signup/applications/${applicationId}/metadata` })
    const metadata: Either<Error, MetadataPayload> = yield call(doFetch, applicationId)

    // dispatch action
    if (isRight(metadata)) {
      yield put(metadataLoaded(metadata.right))
    } else {
      console.error('failed to load metadata', metadata.left)
    }
  })
}

function mapTheme(theme: string): Option<Theme> {
  switch (theme.toLowerCase()) {
    case 'default':
      return some('default')
    case 'alinta':
      return some('alinta')
    default:
      return none
  }
}

function* watchMetadataLoaded() {
  yield takeLatest(
    metadataLoaded.type,
    function* ({ payload }: PayloadAction<MetadataPayload>) {
      const theme = defaultValue(mapTheme(payload.theme), null)
      if (theme != null) {
        yield put(setActiveTheme(theme))
        if (payload.coBrandingPartner) {
          yield put(setCoBrandingPartner(payload.coBrandingPartner))
        }
        if (payload.campaignType) {
          yield put(campaignTypeLoaded(payload.campaignType))
        }
        if (payload.agentPhoneEic) {
          yield put(phoneAgentDetailsLoaded(payload.agentPhoneEic))
        }
        if (payload.callCentreNumber) {
          yield put(setCallCentreNumber(payload.callCentreNumber))
        }
      } else {
        console.error('could not set theme to', payload.theme)
      }
    }
  )
}

export default function* themeSagas() {
  yield all([
    fork(watchThemeQueryString),
    fork(watchServicesQueryString),
    fork(watchEnterPage),
    fork(watchMetadataLoaded),
  ])
}
