import { RootState } from '../state'

export function customerRequestedGas(state: RootState) {
  return state.customer.requestedGas
}

export function customerRequestedElectricity(state: RootState) {
  return state.customer.requestedElectricity
}

export function customerOptInStatusHasBeenTouched(state: RootState) {
  return state.customer.optInStatusTouched
}

export function getQuoteModalOpen(state: RootState) {
  return state.customer.offerQuoteModalOpen
}

export function getQuoteSent(state: RootState) {
  return state.customer.offerQuoteSent
}

export function getQuoteSentTo(state: RootState) {
  return state.customer.offerQuoteSentTo
}
