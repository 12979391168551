import { SiteAddress, SiteAddressSme } from './../api/contracts/siteAddress'
import { Concessions } from '../api/contracts/concessions'
import { AccountInfo } from '../api/contracts/confirmDetails'
import { BusinessDetailsInfo } from '../api/contracts/confirmBusinessDetails'
import { CustomerIdentity } from '../api/contracts/confirmIdentity'
import { Consent } from '../api/contracts/consent'
import { CustomerFeedback } from '../api/contracts/feedback'
import { PostalDeliveryPreference } from '../api/contracts/postalAddress'
import { VisualSiteInspection } from '../api/contracts/visualSiteInspection'
import { NmiEntry } from 'wizard/api/contracts/nmiEntry'
import { emptyAddress } from 'wizard/components/fields/formik/address'
import {
  NswSafetyQuestions,
  VicSafetyQuestions,
} from 'wizard/api/contracts/safetyQuestions'

export const bundle = {
  requestedGas: true,
  requestedElectricity: true,
}

export const customerDetailsForConfirmation: AccountInfo = {
  contact: {
    legalName: {
      salutation: undefined,
      firstName: '',
      middleName: undefined,
      lastName: '',
    },
    phone: '',
    email: '',
  },
  mobileVerificationReceiptId: '',
  accountStartDate: '',
  customerType: 'individual',
  businessDetails: {
    businessEntity: {
      abn: '',
      entityTypeCode: '',
      tradingName: '',
    },
    trusteeDetails: {
      trusteeCategory: 'Company',
      trusteeEntityName: '',
      trusteeAcn: '',
    },
  },
}

export const businessDetailsForConfirmation: BusinessDetailsInfo = {
  contact: {
    legalName: {
      salutation: undefined,
      firstName: '',
      middleName: undefined,
      lastName: '',
    },
    phone: '',
    email: '',
  },
  businessDetails: {
    businessEntity: {
      abn: '',
      entityTypeCode: '',
      tradingName: '',
    },
    trusteeDetails: {
      trusteeCategory: 'Company',
      trusteeEntityName: '',
      trusteeAcn: '',
    },
  },
}

export const siteAddressSme: SiteAddressSme = {
  address: {
    unit: '',
    number: '',
    street: '',
    suburb: '',
    postcode: '',
    state: '',
    country: '',
  },
  addressFilter: undefined
}

export const siteAddress: SiteAddress = {
  address: {
    unit: '',
    number: '',
    street: '',
    suburb: '',
    postcode: '',
    state: '',
    country: '',
  },
  addressFilter: undefined,
  saleType: undefined,
  connectionDate: undefined,
  currentAddress: {
    unit: '',
    number: '',
    street: '',
    suburb: '',
    postcode: '',
    state: '',
    country: '',
  },
}

export const nmiEntry: NmiEntry = {
  nmi: '',
  addressIsCorrect: '',
  address: emptyAddress,
  addressFilter: undefined
}

export const visualSiteInspection: VisualSiteInspection = {
  powerIsCurrentlyOn: undefined,
  electricityConnectionDate: '',
  appointmentTime: '',
  vacantOrOccupied: '',
  locationOfKeys: '',
  locationOfKeysText: '',
  meterAccess: false,
  keysWillBeAccessible: false,
  adultWillBePresent: false,
}

export const customerFeedback = {
  rating: null,
  comment: '',
}

const driverLicence = {
  licenceNumber: '',
  stateOfIssue: '',
  expiryDate: '',
  cardNumber: '',
}

export type DriverLicence = typeof driverLicence

const passport = {
  passportNumber: '',
  countryOfIssue: '',
  expiryDate: '',
}

export type Passport = typeof passport

const medicareCard = {
  medicareNumber: '',
  medicareIrn: '',
  medicareCardColour: '',
  expiryDate: '',
}

export type MedicareCard = typeof medicareCard

export type IdentificationTypes = 'driverLicence' | 'passport' | 'medicareCard'

export const customerIdentity: CustomerIdentity = {
  legalName: {
    salutation: undefined,
    firstName: '',
    lastName: '',
    middleName: '',
  },
  dateOfBirth: '',
  identityDocument: {
    type: 'driverLicence' as IdentificationTypes,
    driverLicence: driverLicence,
    passport: passport,
    medicareCard: medicareCard,
  },
  consentGivenForIdentityVerification: false,
}

const address = {
  fullAddress: '',
  unit: '',
  number: '',
  street: '',
  suburb: '',
  postcode: '',
  state: '',
  country: 'Australia',
  geoLocation: {
    latitude: 0,
    longitude: 0,
  },
}

export const postalDeliveryPreference: PostalDeliveryPreference = {
  address: address,
  sameAsSiteAddress: false,
}

export const concessions: Concessions = {
  needLifeSupport: undefined,
  lifeSupportContactTitle: undefined,
  lifeSupportFuelRequirements: 'electricity',
  requiresMedicalCoolingOrHeating: undefined,
  hasConcessionCard: undefined,
  concessionType: '',
  cardNumber: '',
  cardStartDate: '',
  cardEndDate: '',
  authoriseRetailerForConcession: false,
  additionalAuthorization: undefined,
}

export const vicSafetyQuestions: VicSafetyQuestions = {
  powerCurrentlyOn: undefined,
  recentlyDeEnergised: undefined,
  renovationsPlannedOrInProgress: undefined,
}

export const nswSafetyQuestions: NswSafetyQuestions = {
  customerOrRepresentativeCanEnsureMainSwitchIsOff: undefined,
  powerOffForLongerThanSixMonths: undefined,
  previousRenovationsOrAlterations: undefined,
  renovationsPlannedOrInProgress: undefined,
}

export const consent: Consent = {
  dealId: undefined,
  electricityDealId: undefined,
  gasDealId: undefined,
  acknowledgementText: '',
  distributorConnectionFeeText: '',
  idValidationCheckText: '',
  privacyPolicyText: '',
  termsAndConditionsText: '',
  marketingOptInText: '',
  agreeToIdValidationCheck: false,
  agreeToTermsAndConditions: false,
  agreeToPrivacyPolicy: false,
  agreeToDistributorConnectionFee: false,
  consentToMarketRetailContract: false,
  receiveMarketingInfo: false,
}

export const donePage: CustomerFeedback = {
  rating: undefined,
  comment: '',
}
