import { ErrorPanel } from '@cx/connect-ui'
import React from 'react'
import { useSelector } from 'react-redux'
import { whenSome } from '../../lib/option'
import { formSubmissionFailure } from '../selectors'
import { FormSubmissionErrorType } from '../reducer'

function mapError(err: FormSubmissionErrorType, message: string) {
  switch (err) {
    case 'InvalidAccountStartDate':
      return `The Account Start Date supplied is invalid, please choose a date in the future.`

    case 'ConsentNotProvided':
      return `You must consent to all legal terms, however marketing opt-in is optional.`

    case 'VerificationReceiptMismatch':
      return `The mobile number submitted does not match the verified number on record. Please check your details and re-verify the number.`

    case 'InvalidState':
      return message

    case 'InvalidSiteAddressForOffer':
      return message

    case 'InvalidIdentitySupplied':
      return message

    case 'IdentityVerificationUncertain':
      return message

    case 'IdentityVerificationFailure':
      return message

    default:
      return `An error occured while processing this form, please check your information and try again.`
  }
}

function showPanel(err: FormSubmissionErrorType, message: string) {
  switch (err) {
    case 'NmiLookupError':
      return false

    default:
      return true
  }
}

type Props = {
  className?: string
}

const FormSubmissionFailureErrorPanel = ({ className }: Props) => {
  const error = useSelector(formSubmissionFailure)

  return (
    <>
      {whenSome(
        error,
        (e) =>
          showPanel(e.errorCode, e.message) && (
            <ErrorPanel
              className={className}
              message={mapError(e.errorCode, e.message)}
            />
          )
      )}
    </>
  )
}

export default FormSubmissionFailureErrorPanel
