import React, { ReactNode } from 'react'
import classNames from 'classnames'
import './NextAndBackButtonGroup.scss'

type Props = {
  className?: string
  children: ReactNode
  onClick?: () => void
  padding?: boolean
}

export default ({ className, children, onClick, padding }: Props) => {
  return (
    <div
      className={classNames(className, 'aumo_next-and-back-button-group', {
        'aumo_next-and-back-button-group--padding': padding,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
