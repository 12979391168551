import { OfferQuoteSentTo } from './reducers'
import { createAction } from '@reduxjs/toolkit'

export const customerOptInUpdated = createAction<{ gas: boolean; electricity: boolean }>(
  'CUSTOMER_OPT_IN_UPDATED'
)

export const offerQuoteRequested = createAction('OFFER_QUOTE_REQUESTED')
export const offerQuoteDismissed = createAction('OFFER_QUOTE_DISMISSED')

export const submitOfferQuoteButtonClicked = createAction<{
  email: string
  mobile: string
}>('REQUEST_OFFER_QUOTE_BUTTON_CLICKED')

export const offerQuoteSent = createAction<OfferQuoteSentTo[]>('OFFER_QUOTE_SENT')
