import classNames from 'classnames'
import React, { ReactNode } from 'react'
import './SpeechBubble.scss'

export type BubbleArrowDirection =
  | 'top-1'
  | 'top-2'
  | 'top-3'
  | 'top-4'
  | 'top-5'
  | 'bottom-1'
  | 'bottom-2'
  | 'bottom-3'
  | 'bottom-4'
  | 'bottom-5'

type Props = {
  className?: string
  children: ReactNode
  bubbleArrowDirection: BubbleArrowDirection
}

const SpeechBubble = ({ bubbleArrowDirection, className, children }: Props) => {
  const classes = classNames(className, 'aumo_speech-bubble', {
    [`angle-${bubbleArrowDirection}`]: bubbleArrowDirection,
  })

  return (
    <div className={classes}>
      <div className="aumo_speech-bubble-arrow" />
      {children}
    </div>
  )
}

export default SpeechBubble
