type Config = {
  endpoint: string
  appInsightsInstrumentationKey: string
  reCaptchaSiteKey: string
  partnersWithoutLogo: string[]
  durationForSkippingFeedbackInSeconds: number,
  addressApiEndpoint: string,
  apimSubscriptionKey: string
}

// eslint-disable-next-line import/no-mutable-exports

const parseStringToList = (str: string) => (str || '').split(',').map(a => a.trim().toLowerCase())
let config = {} as Config

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  config = {
    endpoint: 'https://localhost:5001',
    appInsightsInstrumentationKey: '',
    reCaptchaSiteKey: '6Ld2mdsUAAAAADAPUE3Z0b4axbrTeDrCbFbdiGUn',
    partnersWithoutLogo: ['alinta', 'anz'],
    durationForSkippingFeedbackInSeconds: 40,
    addressApiEndpoint: 'https://alinta-digital-api-train.azure-api.net/address',
    apimSubscriptionKey: ''
  }
} else {
  config = {
    endpoint: (window as any).SETTINGS.gatewayEndpoint,
    appInsightsInstrumentationKey: (window as any).SETTINGS.appInsightsInstrumentationKey,
    reCaptchaSiteKey: (window as any).SETTINGS.reCaptchaSiteKey,
    partnersWithoutLogo: parseStringToList((window as any).SETTINGS.partnersWithoutLogo),
    durationForSkippingFeedbackInSeconds: parseInt((window as any).SETTINGS.durationForSkippingFeedbackInSeconds) || 120,
    addressApiEndpoint: (window as any).SETTINGS.addressApiEndpoint,
    apimSubscriptionKey: (window as any).SETTINGS.apimSubscriptionKey
  }
}

export default config
