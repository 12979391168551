import { fromNullable } from 'fp-ts/lib/Option'
import { RootState } from '../state'

type DefaultContent = {
  pageHeader?: string
  pageBodyText?: string
  links?: { [rel: string]: { title: string; href: string } }
}

export const getPageDetails = (defaults: DefaultContent) => (x: RootState) => ({
  pageHeader: x.app.data.pageHeader || defaults.pageHeader,
  pageBodyText: x.app.data.pageBodyText || defaults.pageBodyText,
  links: x.app.data.links || defaults.links,
})

export const getNewOfferAction = (x: RootState) => fromNullable(x.app.data.newOfferAction)
export const getExpiryMessage = (x: RootState) => x.app.data.expiryMessage
export const getCompletionMessage = (x: RootState) => x.app.data.completionMessage
