import * as t from 'io-ts'
import { optional } from './validate'

export const fieldAttributeSchema = t.type({
  isEditable: optional(t.boolean),
  isHidden: optional(t.boolean),
})

export const stepSchema = <T extends t.Props>(x: T) =>
  t.type({
    ...x,
    fieldAttributes: optional(t.UnknownRecord),
    progress: t.type({
      current: t.string,
      steps: t.array(
        t.type({
          key: t.string,
          title: t.string,
        })
      ),
    }),
    _actions: t.UnknownRecord,
    _assets: t.UnknownRecord,
    _links: t.UnknownRecord,
  })

export const legalNameSchema = t.type({
  salutation: optional(t.string),
  firstName: t.string,
  middleName: optional(t.string),
  lastName: t.string,
})

export const contactSchema = t.type({
  legalName: legalNameSchema,
  email: optional(t.string),
  phone: optional(t.string),
})

export type Contact = t.TypeOf<typeof contactSchema>

export const addressSchema = t.type({
  unit: optional(t.string),
  number: t.string,
  street: t.string,
  suburb: t.string,
  state: t.string,
  postcode: t.string,
  country: t.string,
})
