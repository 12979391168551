import { formValidationUpdated } from 'errors/actions'
import { put } from 'redux-saga/effects'

type ErrorMessage = string
type Errors = { [fieldName: string]: ErrorMessage[] }

export class ValidationError {
  name: string
  message: string
  errors: Errors
  stack: string | undefined

  constructor(errors: Errors, msg = 'Validation error') {
    this.name = 'ValidationError'
    this.message = msg
    this.errors = errors
    this.stack = new Error().stack
  }
}

export const isValidationErrorResponse = (res: any) => {
  return res && res.errors
}

export function* handleValidationError(e: ValidationError) {
  let { errors } = e
  yield put(formValidationUpdated(errors))
}
