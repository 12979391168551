import { isLeft, Either, right, left } from 'fp-ts/lib/Either'
import * as t from 'io-ts'
import reporter from 'io-ts-reporters'

export const optional = <C extends t.Mixed>(c: C) => t.union([c, t.undefined])

export function throwIfInvalid<C extends t.Mixed>(
  schema: C,
  schemaName: string,
  data: any
): t.TypeOf<typeof schema> {
  const output = schema.decode(data)
  if (isLeft(output)) {
    const e = {
      message: `Schema Mismatch: unexpected format of ${schemaName} payload.`,
      errors: reporter.report(output),
    }

    throw e
  } else {
    return output.right as t.TypeOf<typeof schema>
  }
}

export function validateSchema<C extends t.Mixed>(
  schema: C,
  schemaName: string,
  data: any,
  logError?: boolean
): Either<Error, t.TypeOf<typeof schema>> {
  try {
    return right(throwIfInvalid(schema, schemaName, data))
  } catch (e) {
    if (logError) {
      console.error('Validation failed', e)
    }
    return left(e)
  }
}
