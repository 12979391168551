import classnames from 'classnames'
import React, { ReactNode, useEffect, useRef } from 'react'
import './Content.scss'

type Props = {
  className?: string
  children?: ReactNode
}

const Content = ({ className, children }: Props) => {
  const node = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (node.current) {
      node.current.focus()
    }
  }, [node])

  return (
    <div
      className={classnames('aumo_content', className)}
      ref={node}
      tabIndex={-1}
    >
      <div className="aumo_content--inner">{children}</div>
    </div>
  )
}

export default Content
