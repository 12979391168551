import { Either, left, right } from 'fp-ts/lib/Either'
import { v4 as uuidv4 } from 'uuid'
import config from '../config'

export class HttpError {
  name: string
  message: string
  url: string
  status: number
  stack: string | undefined

  constructor({
    url,
    status,
    msg = 'Http error',
  }: {
    url: string
    status: number
    msg?: string
  }) {
    this.name = 'HttpError'
    this.message = msg
    this.url = url
    this.status = status
    this.stack = new Error().stack
  }
}

export async function customFetch(url: string, options: RequestInit) {
  const credential = url.includes(config.endpoint) || url.includes('connectexpress.com') ? 'include' : 'same-origin'
  return fetch(url, {
    ...options,
    credentials: credential
  })
}

async function asyncFetch<T extends any>(url: string, options: RequestInit) {
  options = options || {}
  options.headers = (options.headers && new Headers(options.headers)) || new Headers()

  try {
    let response = await customFetch(url, options)
    if (!response.ok && response.status !== 400) {
      throw new HttpError({
        url: response.url,
        status: response.status,
        msg: response.statusText,
      })
    }

    const data = await response.json()

    return data as T
  } catch (e) {
    console.error(`Error performing ${options.method} request to ${url}`, e)
    throw e
  }
}

export function loadHypermedia(path: string) {
  const headers = new Headers()

  return asyncFetch(`${config.endpoint}${path}`, {
    method: 'GET',
    headers,
  })
}

export type FetchRequest = RequestInit & { href?: string; url?: string }

export function fetchAny<T extends any>(opts: FetchRequest) {
  return asyncFetch<T>(`${config.endpoint}${opts.href || opts.url}`, {
    ...opts,
    body: opts.body ? JSON.stringify(opts.body) : null,
    method: opts.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      'cx-request-id': uuidv4(),
    },
  })
}

export type UncheckedResponse = any

export function fetchR<T extends UncheckedResponse>(
  opts: FetchRequest
): Promise<Either<Error, T>> {
  return fetchAny<T>(opts).then(right).catch(left)
}
