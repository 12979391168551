import EmailIcon from './email.png'
import PhoneIcon from './phone.png'
import HomeIcon from './home.png'
import BackIcon from './back.png'

export function mapLinkImage(linkKey: string) {
  switch (linkKey) {
    case 'contact:call':
      return PhoneIcon
    case 'contact:email':
      return EmailIcon
    case 'homepage':
      return HomeIcon
    case 'back':
      return BackIcon
    default:
      return undefined
  }
}
