import { validationResult } from 'cx-validators'

const blankRe = /^\s+$/

function isBlank(val: any) {
  return val == null || val === '' || blankRe.test(val)
}

export type Validator<T> = (value: T) => string | Promise<void> | undefined 

export function required(name: string) {
  return (value: any) => (isBlank(value) ? `'${name}' is required` : undefined)
}

const matchDateStringFormat = (value: any) => /(\d\d\d\d)\-(\d\d)\-(\d\d)/.test(value)

const isValidDateString = (dateStr: string) => {
  if (matchDateStringFormat(dateStr)) {
    const date = new Date(Date.parse(dateStr))
    const isMonthMatched = Number(dateStr.substring(5, 7)) === (date.getMonth() + 1)
    return !isNaN(date.valueOf()) && isMonthMatched
  }
  return false
}

const getAge = (dob: Date) => {
  const age_dt = new Date(Date.now() - dob.getTime());
  const year = age_dt.getUTCFullYear();
  return Math.abs(year - 1970)
}

export function dobValidation() {
  return (value: string) => {
    if (!isValidDateString(value)) {
      return "Please enter the valid date"
    }

    const age = getAge(new Date(Date.parse(value)))

    return (age < 18) ?
      "Date of birth must be greater than 18 years" :
      (age > 125) ?
        "Date of birth must be less than 125 years" : undefined
  }
}

export function driverLicenceExpiryValidation() {
  return (value: string) => {
    if (!isValidDateString(value)) {
      return "Please enter the valid date "
    }

    const currentDate = new Date().setHours(0, 0, 0, 0)
    const expiryDate = new Date(Date.parse(value)).setHours(0, 0, 0, 0)

    return (expiryDate <= currentDate)? 
       "Expiry date must be a future date": undefined
  }
}

export function minLengthValidationQLD(name:string){
  return(value:string)=>(value.length > 0 && value.length <8 ? "Drivers license should be 8 to 9 length": undefined)
}
export function mustHaveValue<T>(expected: T, message: string) {
  return (value: T) => (value !== expected ? message : undefined)
}

export function requiredIf(name: string, predicate: (value: any) => boolean) {
  return (value: any) =>
    predicate(value) ? (isBlank(value) ? `'${name}' is required` : undefined) : undefined
}

const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function isEmail(name: string) {
  return (value: string) =>
    (!value || (value && emailRe.test(value))) ? undefined : `${name} must be a valid email address`
}

export function compose<T>(...validators: Validator<T>[]) {
  return (v: T) =>
    validators
      .map((validator) => validator(v))
      .filter((result) => result !== undefined)[0]
}

export function isValid<T>(validator: Validator<T>, value: T): Promise<T> {
  const res = validator(value)
  if (res === undefined) return new Promise((resolve, _) => resolve(value))
  if (typeof res === 'string') return new Promise((_, reject) => reject(res))
  return res.then((_) => value)
}

export function wrapCxValidator<T>(validator: (t: T) => validationResult<T>) {
  return (v: T) => {
    const result = validator(v)
    if (result.success) return undefined
    return result.errors[0]
  }
}
