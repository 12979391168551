import { ValidationError, handleValidationError } from './validators'
import rollbar from '../integrations/rollbar'
import { HttpError } from './api'
import { handleServerCommunicationError } from '../wizard/sagas/pageSagas'
import appInsights from '../integrations/appInsights'
import { call } from 'redux-saga/effects'

type CustomError = {
  customMessage?: string
  message?: string
}

export const reportError = (message: string, error: any) => {
  rollbar.error(message, error)
  appInsights.trackException({ exception: error, properties: { message } })
  console.error('reported', message)
}

export function handleGenericError(e: CustomError) {
  let message = e.customMessage ? e.customMessage : `${e.message}`
  reportError(message, e)
}

export default function* handleError(e: CustomError) {
  console.error(e)
  if (e instanceof ValidationError) {
    yield call(handleValidationError, e)
  } else if (e instanceof HttpError || e instanceof TypeError) {
    yield call(handleServerCommunicationError, e)
  } else {
    yield call(handleGenericError, e)
  }
}
