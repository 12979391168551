import * as t from 'io-ts'
import { stepSchema } from './wizardStep'
import { optional } from './validate'

export const medicalNeedsFormSchema = t.type({
  requiresMedicalCoolingOrHeating: optional(t.boolean),
  needLifeSupport: optional(t.boolean),
})

export type MedicalNeeds = t.TypeOf<typeof medicalNeedsFormSchema>

export const medicalNeedsSchema = stepSchema({
  text: t.type({
    primary: t.string,
    additional: t.string,
  })
})

export const concessionFormSchema = t.type({
  cardEndDate: optional(t.string),
  cardNumber: optional(t.string),
  cardStartDate: optional(t.string),
  concessionType: optional(t.string),
  hasConcessionCard: optional(t.boolean),
  requiresMedicalCoolingOrHeating: optional(t.boolean),
  needLifeSupport: optional(t.boolean),
  lifeSupportContactTitle: optional(t.string),
  lifeSupportFuelRequirements: optional(t.string),
  authoriseRetailerForConcession: optional(t.boolean),
  additionalAuthorization: optional(t.boolean),
})

const additionalAuthorizations = t.type({
  authorizationText: t.string,
  concessionType: t.string,
})

export type Concessions = t.TypeOf<typeof concessionFormSchema>

export type AdditionalConcessionAuthorization = t.TypeOf<
  typeof additionalAuthorizations
>

export const concessionsSchema = stepSchema({
  concessionTypeOptions: t.array(t.type({ label: t.string, value: t.string })),
  concessions: concessionFormSchema,
  text: t.type({
    primary: t.string,
    additional: t.string,
    authorization: t.string,
    acceptance: t.string,
    additionalAuthorizations: t.array(additionalAuthorizations),
  }),
})
