import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import ProgressIndicatorView from '../../components/ProgressIndicator'
import L from '../../lodashFp'

type Props = {
  className?: string
}

const ProgressIndicator = ({ className }: Props) => {
  const steps: any[] = useSelector(
    L.flow(L.get('app.data.progress.steps'), L.defaultTo([]))
  )
  const current: string = useSelector(L.get('app.data.progress.current'))

  const totalSteps = steps.length
  let activeStep = _.findIndex(steps, (s) => s.key === current)

  // TODO: remove fallback (lowercase 'done') when backend is generally available
  if (current === 'Done' || current === 'done') {
    activeStep = steps.length
  }

  if (activeStep === -1) return null

  return <ProgressIndicatorView stepCount={totalSteps} currentStep={activeStep} />
}

export default ProgressIndicator
