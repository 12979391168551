import * as t from 'io-ts'
import { stepSchema } from './wizardStep'
import { optional } from './validate'

export const consentFormSchema = t.type({
  acknowledgementText: t.string,
  distributorConnectionFeeText: t.string,
  termsAndConditionsText: t.string,
  idValidationCheckText: t.string,
  privacyPolicyText: t.string,
  marketingOptInText: t.string,
  dealId: optional(t.string),
  electricityDealId: optional(t.string),
  gasDealId: optional(t.string),
  consentToMarketRetailContract: optional(t.boolean),
  agreeToDistributorConnectionFee: optional(t.boolean),
  agreeToTermsAndConditions: optional(t.boolean),
  agreeToPrivacyPolicy: optional(t.boolean),
  agreeToIdValidationCheck: optional(t.boolean),
  receiveMarketingInfo: optional(t.boolean),
})

export type Consent = t.TypeOf<typeof consentFormSchema>

export const consentSchema = stepSchema({
  consent: consentFormSchema,
  text: t.type({
    primary: t.string,
    additional: t.string,
  }),
})
