import { createReducer } from '@reduxjs/toolkit'
import {
  customerOptInUpdated,
  offerQuoteDismissed,
  offerQuoteRequested,
  offerQuoteSent,
  submitOfferQuoteButtonClicked,
} from './actions'

const initialState = {
  requestedElectricity: true,
  requestedGas: true,
  optInStatusTouched: false,
  offerQuoteModalOpen: false,
  offerQuoteSent: false,
  offerQuoteSentTo: [] as OfferQuoteSentTo[],
}

export type OfferQuoteSentTo = 'Email' | 'Mobile'

export type State = typeof initialState

// This reducer is responsible for holding state about customer actions
// that do not directly relate to filling out a wizard step
export default createReducer(initialState, (b) => {
  b.addCase(customerOptInUpdated, (s, { payload: optIn }) => {
    s.requestedGas = optIn.gas
    s.requestedElectricity = optIn.electricity
    s.optInStatusTouched = true
  })

  b.addCase(offerQuoteRequested, (s, _) => {
    s.offerQuoteModalOpen = true
  })

  b.addCase(offerQuoteDismissed, (s, _) => {
    s.offerQuoteModalOpen = false
  })

  b.addCase(submitOfferQuoteButtonClicked, (s, _) => {
    s.offerQuoteSentTo = []
  })

  b.addCase(offerQuoteSent, (s, { payload: offerQuoteSentTo }) => {
    s.offerQuoteSent = true
    s.offerQuoteSentTo = offerQuoteSentTo
  })
})
