import { createAction, createReducer } from '@reduxjs/toolkit'

export const verificationCodeRequested = createAction<{
  mobile: string
  reCaptchaToken: string
}>('MOBILE_NUMBER_VERIFICATION_CODE_REQUESTED')

export const verificationCodeSent = createAction<{ nonce: string; receiptId: string }>(
  'MOBILE_NUMBER_VERIFICATION_CODE_SENT'
)

export const verificationCodeFailedToSend = createAction(
  'MOBILE_NUMBER_VERIFICATION_CODE_FAILED_TO_SEND'
)

export const mobileNumberVerificationAttempted = createAction<{
  code: string
  onSuccess: (receiptId: string) => void
}>('MOBILE_NUMBER_VERIFICATION_ATTEMPTED')

export const mobileNumberVerificationSucceeded = createAction(
  'MOBILE_NUMBER_VERIFICATION_SUCCEEDED'
)

export const mobileNumberVerificationFailed = createAction(
  'MOBILE_NUMBER_VERIFICATION_FAILED'
)

export type MobileNumberVerificationLifecycleState =
  | 'init'
  | 'codeRequested'
  | 'codeFailedToSend'
  | 'codeSent'
  | 'verificationRequested'
  | 'verificationFailed'
  | 'verificationSucceeded'

const initialState = {
  state: 'init' as MobileNumberVerificationLifecycleState,
  receiptId: undefined as string | undefined,
  nonce: undefined as string | undefined,
}

export type MobileNumberVerificationState = typeof initialState

export const mobileNumberVerificationReducer = createReducer(initialState, (b) => {
  b.addCase(verificationCodeRequested, (s, _) => {
    s.state = 'codeRequested'
    s.nonce = undefined
    s.receiptId = undefined
  })

  b.addCase(verificationCodeSent, (s, { payload: { nonce, receiptId } }) => {
    s.state = 'codeSent'
    s.nonce = nonce
    s.receiptId = receiptId
  })

  b.addCase(verificationCodeFailedToSend, (s, _) => {
    s.state = 'codeFailedToSend'
  })

  b.addCase(mobileNumberVerificationAttempted, (s, _) => {
    s.state = 'verificationRequested'
  })

  b.addCase(mobileNumberVerificationSucceeded, (s, _) => {
    s.state = 'verificationSucceeded'
  })

  b.addCase(mobileNumberVerificationFailed, (s, _) => {
    s.state = 'verificationFailed'
  })
})
