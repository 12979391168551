import md from '../md'
import { ProductAdvice } from '../../components/energy/types'

export const mapProductAdvice = (p: ProductAdvice) => {
  switch (p.type.toLowerCase()) {
    case 'comparison':
      if (!p.comparison) {
        throw new Error(
          'Product advice type was comparison, but no comparison data found in payload.'
        )
      }

      return {
        ...p,
        comparison: {
          ...p.comparison,
          percentageDifferenceToRefBillText: md.render(
            p.comparison.percentageDifferenceToRefBillText
          ),
          refBillDescription: md.render(p.comparison.refBillDescription),
        },
      }

    case 'necffallback':
      return p

    case 'credit':
    default:
      return p
  }
}
