import { useField, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { Validator } from '../validation/validators'
import { fromNullable } from 'fp-ts/lib/Option'
import { defaultValue } from './option'

// These utils originate from cx-movers-admin
// They should be rolled into connect-ui eventually, probably under connect-ui/formik given
// their relationship to the framework

export type CommonFieldProps = {
  name: string
  disabled?: boolean
}

const findErrors = (formStatus: any, name: string, includeChildren?: boolean) => {
  const directErrors = (formStatus && formStatus[name]) || []

  const parentField = name + '.'
  const childErrors =
    formStatus &&
    includeChildren &&
    Object.entries(formStatus)
      .filter(([k, _]) => k.startsWith(parentField))
      .flatMap(([_, v]) => v)

  return includeChildren ? directErrors.concat(childErrors) : directErrors
}

export const useFormikField = <TVal extends any>(
  name: string,
  validator?: Validator<TVal>,
  includeChildErrors?: boolean
) => {
  const [field, meta, helpers] = useField<TVal>({
    name,
    validate: validator,
  })
  const form = useFormikContext()

  return useMemo(() => {
    const error =
      meta.error && meta.touched
        ? { messageType: 'error' as const, message: meta.error }
        : {}

    const fieldErrors = findErrors(form.status, name, includeChildErrors)
    const status =
      form.status && fieldErrors.length > 0
        ? {
            messageType: 'error' as const,
            message: defaultValue(
              fromNullable(fieldErrors[0]),
              'No error message provided.'
            ),
          }
        : {}

    return [
      {
        ...field,
        ...error,
        ...status,
      },
      helpers,
    ] as const
  }, [field, meta, helpers, form.status, name, includeChildErrors])
}
