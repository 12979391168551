import { Option, some, none } from 'fp-ts/lib/Option'

export function getApplicationId(): Option<string> {
  return getApplicationIdFromPath(window.location.pathname)
}

export function getApplicationIdFromPath(url: string): Option<string> {
  let array = url.match(/signup\/applications\/([^/]*)\/?/)
  if (array && array.length > 1 && array[1].length >= 32 && array[1].length <= 36) {
    let applicationId = array[1]
    return some(applicationId)
  }

  return none
}
