import { createReducer } from '@reduxjs/toolkit'
import { none, Option, some } from 'fp-ts/lib/Option'
import {
  apiContractFailureCleared,
  apiContractFailureEncountered,
  formFieldValidationCleared,
  formFieldValidationUpdated,
  formSubmissionFailureCleared,
  formSubmissionFailureEncountered,
  formValidationUpdated,
  formValidationCleared,
} from './actions'

export type FormSubmissionErrorType =
  | 'InvalidAccountStartDate'
  | 'InvalidState'
  | 'ConsentNotProvided'
  | 'InvalidSiteAddressForOffer'
  | 'NmiLookupError'
  | 'NoPriceInformationFound'
  | 'VerificationReceiptMismatch'
  | 'InvalidIdentitySupplied'
  | 'IdentityVerificationUncertain'
  | 'IdentityVerificationFailure'

type FormSubmissionError = {
  errorCode: FormSubmissionErrorType
  message: string
}

export type FormValidationErrors = { [path: string]: string[] }

const initialState = {
  apiContractError: false,
  formSubmissionError: none as Option<FormSubmissionError>,
  formValidationErrors: {} as FormValidationErrors,
}

export type ErrorState = typeof initialState

// New actions
// - field validation error updated (formKey, fieldPath, errorMessage)
// - form validation updated (formKey, { [path: string]: errorMessage })

export const errorReducer = createReducer(initialState, (b) => {
  b.addCase(apiContractFailureEncountered, (s, _) => {
    s.apiContractError = true
  })

  b.addCase(apiContractFailureCleared, (s, _) => {
    s.apiContractError = false
  })

  b.addCase(
    formSubmissionFailureEncountered,
    (s, { payload: { errorCode, message } }) => {
      s.formSubmissionError = some({
        message,
        errorCode: errorCode as FormSubmissionErrorType,
      })
    }
  )

  b.addCase(formSubmissionFailureCleared, (s, _) => {
    s.formSubmissionError = none
  })

  b.addCase(formValidationUpdated, (s, a) => {
    s.formValidationErrors = a.payload
  })

  b.addCase(formValidationCleared, (s, a) => {
    s.formValidationErrors = {}
  })

  b.addCase(formFieldValidationUpdated, (s, { payload: { fieldPath, message } }) => {
    s.formValidationErrors[fieldPath] = [message]
  })

  b.addCase(formFieldValidationCleared, (s, { payload: fieldPath }) => {
    delete s.formValidationErrors[fieldPath]
  })
})
