import { Theme } from '@cx/connect-ui/lib/lib/theme'
import { createAction } from '@reduxjs/toolkit'
import { CampaignType, AgentPhoneEicDetails } from 'wizard/reducers'

export type CoBrandingPartner = {
  name: string
  logoUrl: string
}
export type MetadataPayload = {
  theme: string
  coBrandingPartner?: CoBrandingPartner
  campaignType?: CampaignType
  callCentreNumber?: null
  agentPhoneEic?: AgentPhoneEicDetails
}
export const metadataLoaded = createAction<MetadataPayload>('METADATA_LOADED')

export const setActiveTheme = (theme: Theme) =>
  ({
    type: 'ACTIVE_THEME_SET',
    payload: theme,
  } as const)

export const setCoBrandingPartner = (partner: CoBrandingPartner) =>
  ({
    type: 'COBRANDING_PARTNER_SET',
    payload: partner,
  } as const)

export const setCallCentreNumber = (callCentreNumber: string) =>
  ({
    type: 'CALL_CENTRE_NUMBER_SET',
    payload: callCentreNumber,
  } as const)

export type ThemeActions =
  | ReturnType<typeof setActiveTheme>
  | ReturnType<typeof setCoBrandingPartner>
  | ReturnType<typeof setCallCentreNumber>
