import { appWindow } from '../window'

/*
  The window.SETTINGS are injected via Azure and features can be dynamically toggled.
  Toggles will be false (undefined) by default on Test, Staging and Prod.
*/

export type FeatureToggles = {
  SETTINGS: { features: { [id: string]: boolean }; [id: string]: any }
}

let features = {
  PROMPT_TO_VERIFY_MOBILE_NUMBER: true,
  IDENTITY_VERIFICATION: false,
  CAPTURE_CURRENT_ADDRESS_FOR_MOVE_IN: true,
  CAPTURE_CUSTOMERS_SALUTATION: true,
  CAPTURE_DRIVER_LICENCE_CARD_NUMBER: true,
  SHOW_DRIVER_LICENCE_TOOLTIP_IMG: true,
  ALINTA_STOP_SALE: false,
  DISABLE_SOHO: false,
  VIC_DRIVER_LICENCE_CARD_NUMBER_MANDATORY: true,
  SHOW_ELEC_PVE_NOTICE: false
}

if (appWindow.SETTINGS && appWindow.SETTINGS.features) {
  features = {
    ...features,
    ...appWindow.SETTINGS.features,
  }
}

// Consider removing the features if they're still on in production
export const CAPTURE_CURRENT_ADDRESS_FOR_MOVE_IN =
  !!features.CAPTURE_CURRENT_ADDRESS_FOR_MOVE_IN
export const CAPTURE_CUSTOMERS_SALUTATION = !!features.CAPTURE_CUSTOMERS_SALUTATION
export const CAPTURE_DRIVER_LICENCE_CARD_NUMBER =
  !!features.CAPTURE_DRIVER_LICENCE_CARD_NUMBER
export const SHOW_DRIVER_LICENCE_TOOLTIP_IMG = !!features.SHOW_DRIVER_LICENCE_TOOLTIP_IMG
export const VIC_DRIVER_LICENCE_CARD_NUMBER_MANDATORY = !!features.VIC_DRIVER_LICENCE_CARD_NUMBER_MANDATORY

export const ALINTA_STOP_SALE =
  !!features.ALINTA_STOP_SALE && !document.cookie.includes('ALINTA_STOP_SALE_BYPASS=true')

export const DISABLE_SOHO = !!features.DISABLE_SOHO

// Long-lived feature toggles
export const PROMPT_TO_VERIFY_MOBILE_NUMBER = !!features.PROMPT_TO_VERIFY_MOBILE_NUMBER
export const IDENTITY_VERIFICATION = !!features.IDENTITY_VERIFICATION

export const SHOW_ELEC_PVE_NOTICE =!!features.SHOW_ELEC_PVE_NOTICE