import _ from 'lodash'
import { call, put, takeLatest, select, all, fork } from 'redux-saga/effects'
import { fetchAny } from '../../lib/api'
import history from '../../lib/history'
import { Action } from 'redux'
import {
  pageDataIsPrepared,
  linkClicked,
  backButtonClicked,
  skipButtonClicked,
} from '../actions'
import handleError from '../../lib/handleError'
import * as selectors from '../selectors'

const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i')

type PayloadAction<T> = Action & {
  payload: T
}

function* navigateTo(url: string, showTransition = true) {
  const { pathname } = history.location
  if (!url || pathname === url) return

  if (showTransition) {
    yield put(pageDataIsPrepared(false))
  }

  // Why? Poor type signature support on redux-saga's part
  if (absoluteUrlRegex.test(url)) {
    window.location.href = url
  } else {
    yield call(() => history.push(url))
  }
}

function* handleLinkClicked() {
  yield takeLatest(
    linkClicked.type,
    function* ({
      payload: { href, showTransition },
    }: PayloadAction<{ href: string; showTransition: boolean }>) {
      try {
        yield call(navigateTo, href, showTransition)
      } catch (e) {
        yield call(handleError, e)
      }
    }
  )
}

function* handleBackButtonClicked() {
  yield takeLatest(
    backButtonClicked.type,
    function* ({ payload }: PayloadAction<string>) {
      const backLink = yield select(selectors.getBackLink) || {}
      const route = payload || backLink.href

      if (!route) {
        yield put(pageDataIsPrepared(false))
        yield call(history.goBack)
        return
      }

      yield put(linkClicked({ href: route, showTransition: false }))
    }
  )
}

function* handleSkipButtonClicked() {
  yield takeLatest(
    skipButtonClicked.type,
    function* ({ payload }: PayloadAction<string>) {
      yield put(linkClicked({ href: payload, showTransition: false }))
    }
  )
}

export function* autofollow(data: any): any {
  const autofollowLink = _.get(data, '_links.autofollow')
  const currentPath = _.get(history, 'location.pathname')

  if (!autofollowLink) return false

  const autofollowLinkHref = autofollowLink.href ? autofollowLink.href : autofollow
  console.log('data, autofollowLinkHref', data, autofollowLinkHref)

  if (autofollowLinkHref && absoluteUrlRegex.test(autofollowLinkHref)) {
    window.location.href = autofollowLinkHref
    return true
  }

  if (autofollowLinkHref && autofollowLinkHref !== currentPath) {
    yield call(navigateTo, autofollowLinkHref)
    return true
  }

  const autofollowAction = _.get(data, '_actions.autofollow')
  if (!_.isEmpty(autofollowAction)) {
    try {
      const data = yield call(fetchAny, autofollowAction)
      return yield call(autofollow, data)
    } catch (e) {
      console.error('Error while loading hypermedia', e)
    }
  }
}

export default function* root() {
  yield all([
    fork(handleLinkClicked),
    fork(handleBackButtonClicked),
    fork(handleSkipButtonClicked),
  ])
}
